/**
 * @flow
 *
 * @format
 */
import * as xmlConvert from 'xml-js';
import BaseItem, { ItemTypes } from './BaseItem';
import type { ObjectMap } from './Shortcuts';
import { TriggeredItemConditions } from './TriggeredItem';

/* ***************************
  CLASS
*************************** */
export type AppPOI = {};

export const POIItemPOITypes = {
  Start: 'Start', // Start point of scenario
  Objective: 'Objective', // Main Objective for the scenario
  Major: 'Major', // An important point
  Interactive: 'Interactive', // A point with some interaction
  NPC: 'NPC', // A Point with an NPC
  Hidden: 'Hidden',
};

type POIItemPOITypesType = $Values<typeof POIItemPOITypes>;

export const POIItemProgressStates = {
  Default: 'Default',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Archived: 'Archived',
};
export type POIItemProgressStatesType = $Values<typeof POIItemProgressStates>;

export const POIItemPatrolTypes = {
  None: 'None',
  OneWay: 'OneWay',
  GoingsComings: 'GoingsComings',
  Cyclic: 'Cyclic',
};
export type POIItemPatrolTypesType = $Values<typeof POIItemPatrolTypes>;

type Coordinate = { latitude: number, longitude: number, altitude?: number };

export type PatrolCoordinateItem = { coordinate: Coordinate, speedKmH: number };

export default class POIItem extends BaseItem {
  coordinate: Coordinate;

  distanceMinToTrigger: number;

  patrolType: POIItemPatrolTypesType;

  patrolCoordinates: ?(Coordinate[]);

  patrolSpeeds: ?(number[]);

  visibleOnMap: boolean;

  autocompleteAfterLaunch: boolean;

  autoPauseOnEachReach: boolean;

  launchOnFirstReach: boolean;

  visibleAfterCompleted: boolean;

  disableNotification: boolean;

  poiTypes: ?ObjectMap<POIItemPOITypesType>;

  constructor(json: any) {
    if (json instanceof POIItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type,
      meta,
      coordinate,
      distanceMinToTrigger,
      patrolType,
      patrolCoordinates,
      patrolSpeeds,
      visibleOnMap,
      autocompleteAfterLaunch,
      autoPauseOnEachReach,
      launchOnFirstReach,
      visibleAfterCompleted,
      disableNotification,
      poiType,
      poiTypes,
      ...newMeta
    } = json;
    this.type = ItemTypes.POI;
    this.coordinate = coordinate ? { ...coordinate } : { latitude: 0, longitude: 0, altitude: 0 };
    this.distanceMinToTrigger = distanceMinToTrigger;
    this.patrolType = patrolType || POIItemPatrolTypes.None;
    this.patrolCoordinates = patrolCoordinates ? [...patrolCoordinates] : [];
    this.patrolSpeeds = patrolSpeeds ? [...patrolSpeeds] : [];
    this.visibleOnMap = visibleOnMap !== '' ? visibleOnMap : false;
    this.autocompleteAfterLaunch = autocompleteAfterLaunch !== '' ? autocompleteAfterLaunch : false;
    this.autoPauseOnEachReach = autoPauseOnEachReach !== '' ? autoPauseOnEachReach : false;
    this.launchOnFirstReach = launchOnFirstReach !== '' ? launchOnFirstReach : false;
    this.visibleAfterCompleted = visibleAfterCompleted !== '' ? visibleAfterCompleted : false;
    this.disableNotification = disableNotification !== '' ? disableNotification : false;
    this.poiTypes = { ...poiTypes };
    this.setMeta({ ...json.meta, ...newMeta });
  }

  getPolyline() {
    switch (this.patrolType) {
      case POIItemPatrolTypes.OneWay:
      case POIItemPatrolTypes.GoingsComings: {
        const res = [];
        if (this.coordinate) {
          res.push(this.coordinate);
        }
        if (this.patrolCoordinates.length) {
          res.push(...this.patrolCoordinates);
        }

        return res;
      }
      case POIItemPatrolTypes.Cyclic: {
        const res = [];
        if (this.coordinate) {
          res.push(this.coordinate);
        }
        if (this.patrolCoordinates.length) {
          res.push(...this.patrolCoordinates);
        }
        if (this.coordinate) {
          res.push(this.coordinate);
        }

        return res;
      }
      default: {
        const res = [];
        if (this.coordinate) {
          res.push(this.coordinate);
        }

        return res;
      }
    }
  }

  isRemoveLocked() {
    return this.id === 'start_poi';
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.type = ItemTypes.POI;
    res.coordinate = { ...this.coordinate };
    res.distanceMinToTrigger = this.distanceMinToTrigger;
    res.patrolType = this.patrolType;
    res.patrolCoordinates = this.patrolCoordinates ? [...this.patrolCoordinates] : [];
    res.patrolSpeeds = this.patrolSpeeds ? [...this.patrolSpeeds] : [];
    res.visibleOnMap = this.visibleOnMap;
    res.autocompleteAfterLaunch = this.autocompleteAfterLaunch;
    res.autoPauseOnEachReach = this.autoPauseOnEachReach;
    res.launchOnFirstReach = this.launchOnFirstReach;
    res.visibleAfterCompleted = this.visibleAfterCompleted;
    res.disableNotification = this.disableNotification;
    res.poiTypes = { ...this.poiTypes };
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.type = ItemTypes.POI;
    res.coordinate = { ...this.coordinate };
    res.distanceMinToTrigger = this.distanceMinToTrigger;
    res.patrolType = this.patrolType;
    res.patrolCoordinates = this.patrolCoordinates ? [...this.patrolCoordinates] : [];
    res.patrolSpeeds = this.patrolSpeeds ? [...this.patrolSpeeds] : [];
    res.visibleOnMap = this.visibleOnMap;
    res.autocompleteAfterLaunch = this.autocompleteAfterLaunch;
    res.autoPauseOnEachReach = this.autoPauseOnEachReach;
    res.launchOnFirstReach = this.launchOnFirstReach;
    res.visibleAfterCompleted = this.visibleAfterCompleted;
    res.disableNotification = this.disableNotification;
    res.poiTypes = { ...this.poiTypes };
    return res;
  }

  calculateSelfUnlockedValues(startItemId: string) {
    const selfUnlockedValues = [
      {
        state: TriggeredItemConditions.Discovered,
      },
      {
        state: TriggeredItemConditions.FirstReach,
      },
      {
        state: TriggeredItemConditions.Reached,
      },
      {
        state: TriggeredItemConditions.Launched,
      },
    ];
    if (this.autocompleteAfterLaunch) {
      selfUnlockedValues.push({
        state: TriggeredItemConditions.Completed,
      });
    }
    if (this.autoPauseOnEachReach) {
      selfUnlockedValues.push({
        state: TriggeredItemConditions.Paused,
      });
    }
    if (this.launchOnFirstReach) {
      selfUnlockedValues.push({
        state: TriggeredItemConditions.Launched,
      });
    }
    return [...super.calculateSelfUnlockedValues(startItemId), ...selfUnlockedValues];
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[], startItemId: string) {
    const res = super.checkRelease(items, locales, startItemId);

    if (this.poiTypes && !Object.keys(this.poiTypes).length) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_POI_NO_TYPE',
        details: { message: 'At least one POI type should be set' },
      });
    }
    if (!this.distanceMinToTrigger || this.distanceMinToTrigger <= 0) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_POI_DISTANCE_NEGATIVE_OR_NULL',
        details: { message: 'At least one POI type should be set' },
      });
    } else if (this.distanceMinToTrigger <= 15) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_POI_DISTANCE_SHORT',
        details: { message: 'At least one POI type should be set' },
      });
    }
    if (this.patrolType !== POIItemPatrolTypes.None) {
      if (!this.patrolCoordinates || !this.patrolCoordinates.length) {
        res.push({
          level: 'warn',
          item: this.id,
          message: 'E_POI_PATROL_NO_ADD_COORDINATE',
          details: { message: 'At least one additional coordinate is needed when patrol is not NONE' },
        });
      } else if (!this.patrolSpeeds || !this.patrolSpeeds.length) {
        res.push({
          level: 'warn',
          item: this.id,
          message: 'E_POI_PATROL_NO_SPEEDS',
          details: { message: 'At least one speed is needed when patrol is not NONE' },
        });
      }
    } else if (this.patrolCoordinates && this.patrolCoordinates.length) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_POI_MULTIPLE_COORDINATE',
        details: { message: 'There is more than one coordinate but patrol is NONE' },
      });
    }
    return res;
  }

  getWptJson(coordinate: Coordinate = this.coordinate, step?: number) {
    let name = this.id;
    if (step !== undefined) {
      name = `${name}-${step}`;
    }
    return {
      _attributes: {
        lat: coordinate.latitude,
        lon: coordinate.longitude,
      },
      ele: coordinate.altitude || 0,
      name,
    };
  }

  serializeGpx() {
    let wpt: Array<Object> | Object;
    const firstWpt = this.getWptJson(this.coordinate);
    if (this.patrolType !== POIItemPatrolTypes.None && this.patrolCoordinates) {
      wpt = [firstWpt];
      this.patrolCoordinates.forEach((patrolCoordinate, index) => {
        wpt.push(this.getWptJson(patrolCoordinate, index + 1));
      });
    } else {
      wpt = firstWpt;
    }
    const json = {
      _declaration: {
        _attributes: {
          version: '1.0',
        },
      },
      gpx: {
        metadata: {
          author: 'Vidok editor',
          copyright: 'Eritaj Studio',
        },
        _attributes: {
          version: '1.1',
          creator: 'Vidok editor',
        },
        wpt,
      },
    };
    const options = { compact: true, ignoreComment: true, spaces: 4 };
    const gpxContent = xmlConvert.json2xml(json, options);
    return gpxContent;
  }
}
