/**
 * @flow
 *
 * @format
 */

import type { Seasons } from 'src/data/types/Seasons';
import React from 'react';

type Props = {
  season: Seasons,
  onClick: any,
};

const SeasonCard = (props: Props) => {
  let background = props.season.backgroundImage?.files?.default?.url;
  background = `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), ${
    background ? `url(${background})` : ''
  } center / cover no-repeat`;
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={props.season.seasonId} onClick={props.onClick}>
      <button
        className="scenarioButton btn list-group-item mb-3 list-group-item-action d-flex align-items-start"
        style={{ background }}
      >
        <div className="d-flex flex-column scenarioContent w-100 ">
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{props.season.seasonName}</h5>
          </div>
          <small className="scenarioMeta">{props.season.seasonDescription}</small>
          <br />
          <br />
          <small className="scenarioMeta">{props.season.seasonId}</small>
        </div>
      </button>
    </div>
  );
};

export default SeasonCard;
