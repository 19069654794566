/**
 * @flow
 * @format
 */
import * as React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import HelpButton from '../HelpButton';

type InputSeasonScenariosArrayProps = {
  className: string,
  style?: any,
  fieldName: string,
  value?: ?string,
  label?: string,
  help?: ?string,
  separatorBefore?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  handleChange?: (any) => any,
  helpInfos: any,
  labelSmall: boolean,
  suffix?: ?string,
  inlineTitle?: boolean,
  spellCheck?: boolean,
  className?: string,
  onFocus?: () => any,
  onClick?: () => any,
  withoutBrackets: boolean,
  isCoordinates: boolean,
  isNumber: boolean,
  t: (key: string) => string,
  inputStyle?: ?any,
  prependStyle?: ?any,
  availableValues: Array<any>,
  locale: string
};

const InputSeasonScenariosArray = ({
  className,
  style,
  fieldName,
  value,
  label,
  help,
  separatorBefore = false,
  disabled = false,
  hidden = false,
  handleChange,
  helpInfos = undefined,
  inlineTitle,
  onFocus,
  onClick,
  withoutBrackets = false,
  isCoordinates = false,
  isNumber = false,
  t,
  inputStyle = {},
  prependStyle = {},
  availableValues,
  locale
}: InputSeasonScenariosArrayProps) => {
  const [formattedValue, setFormattedValue] = React.useState<Array<string>>(['']);

  /**
   * Format the event for the parent to handle
   */
  const eventForParent = (id: string, value: string) => {
    return {
      target: {
        id,
        value,
      },
    };
  };

  /**
   * Converts the values to JSON format
   */
  const valueToJSON = (values: Array<string>) => {
    let valuesToChange = [...values];
    if (isCoordinates || isNumber) {
      valuesToChange = valuesToChange.map((val) => {
        const newVal = parseFloat(val);
        // Not a number, we ignore and return a string
        return Number.isNaN(newVal) ? val : newVal;
      });
    }
    let jsonValues = JSON.stringify(valuesToChange);
    if (withoutBrackets) {
      jsonValues = jsonValues.replace('[', '').replace(']', '');
    }
    return jsonValues;
  };

  const handleChangeFormat = (event, index, field) => {
    const { value } = event.target;
    const newFormattedValue = [...formattedValue];
    newFormattedValue[index] = {
      ...newFormattedValue[index],
      [field]: value,
    };
    setFormattedValue(newFormattedValue);
    handleChange(eventForParent(fieldName, valueToJSON(newFormattedValue)));
  };

  const formatValueToStates = (jsonString: string) => {
    try {
      const arrayedValue = JSON.parse(`${withoutBrackets ? '[' : ''}${jsonString}${withoutBrackets ? ']' : ''}`);
      const { length } = arrayedValue;
      if (length === 0) {
        arrayedValue.push('');
      }
      setFormattedValue(arrayedValue);
    } catch (err) {
      setFormattedValue(['']);
    }
  };

  React.useEffect(() => {
    formatValueToStates(value);
  }, [value]);

  const prefixStyle = { ...prependStyle, borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' };

  const scenarioStatus = ['comingSoon', 'pro', 'available'];

  return (
    <div className={`w-100 ${className || ''}`} key={fieldName} hidden={hidden} style={style} onClick={onClick}>
      {separatorBefore && <hr />}
      {(label || helpInfos) && (
        <div className="form-group">
          <label className={inlineTitle ? 'input-group-prepend mr-2' : 'strong text-capitalize'} htmlFor={fieldName}>
            {`${label} `}
            {helpInfos &&
              Array.isArray(helpInfos) &&
              helpInfos.map((helpInfo, index) => (
                <HelpButton
                  key={fieldName + index}
                  helpStrings={helpInfo.content}
                  id={fieldName}
                  title={helpInfo.title}
                  label={helpInfo.btnLabel}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              ))}
          </label>
        </div>
      )}
      {formattedValue.map((valueFormatted, index) => (
        <div className="mb-2" key={`${fieldName}json${index}`}>
          <div className="input-group w-100 h-100">
            <div className="input-group-prepend" style={{ width: '10rem' }}>
              <span className="input-group-text w-100" id="basic-addon1" style={prefixStyle}>
                {t('screens.editSeason.edit.scenarioId')}
              </span>
            </div>
            <select
              className={`form-control custom-select`}
              id={`${fieldName}-${index}`}
              onChange={(event) => handleChangeFormat(event, index, 'scenarioId')}
              value={valueFormatted?.scenarioId}
              disabled={disabled}
              aria-describedby={`${fieldName}Help`}
              placeholder={''}
              onFocus={onFocus}
              style={inputStyle}
            >
              <option value={''}>{''}</option>
              {availableValues &&
                availableValues.map((element) => (
                  <option key={element.id} value={element.id}>
                    {element.id}
                  </option>
                ))}
            </select>
          </div>
          <div className="input-group w-100 h-100 mt-2">
            <div className="input-group-prepend" style={{ width: '10rem' }}>
              <span className="input-group-text w-100" id="basic-addon1" style={prefixStyle}>
                {t('screens.editSeason.edit.scenarioStatus')}
              </span>
            </div>
            <select
              className={`form-control custom-select`}
              id={`${fieldName}-status-${index}`}
              onChange={(event) => handleChangeFormat(event, index, 'status')}
              value={valueFormatted?.status}
              disabled={disabled}
              aria-describedby={`${fieldName}Help`}
              placeholder={''}
              onFocus={onFocus}
              style={inputStyle}
            >
              <option value={''}>{''}</option>
              {scenarioStatus &&
                scenarioStatus.map((element) => (
                  <option key={element} value={element}>
                    {t(`screens.editSeason.edit.status.${element}`)}
                  </option>
                ))}
            </select>
          </div>
        </div>
      ))}
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );
};

export default compose(withTranslation('default'))(InputSeasonScenariosArray);
