/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedFile from './LocalizedFile';

export const ImagePositioning = {
  cover: 'cover',
  stretch: 'stretch',
  contain: 'contain',
};

export type ImagePositioningType = {
  cover: 'cover',
  stretch: 'stretch',
  contain: 'contain',
};

export const Animation = {
  opacity: 'opacity',
  rotate: 'rotate',
  thunder: 'thunder',
};

export type AnimationType = {
  opacity: 'opacity',
  rotate: 'rotate',
  thunder: 'thunder',
};

export type AnimationCycle = {
  values: Array<number>,
  timings: Array<number>,
};

export type AnimationHandler = {
  type: AnimationType,
  cycle: AnimationCycle,
  numberLoops: number,
};

export default class LayerItem extends BaseItem {
  layer: ?LocalizedFile;

  imagePositioning: ImagePositioningType;

  animations: Array<AnimationHandler>;

  constructor(json: any) {
    if (json instanceof LayerItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { layer, imagePositioning, animations } = json;
    this.type = ItemTypes.LayerItem;
    this.layer = new LocalizedFile(this.id, 'layer', layer);
    this.imagePositioning = imagePositioning !== undefined ? imagePositioning : ImagePositioning.stretch;
    this.animations = animations !== undefined ? animations : [];
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    if (this.layer && this.layer.hasFile()) {
      res.layer = this.layer.serializeForApp();
    }
    res.imagePositioning = this.imagePositioning;
    res.animations = this.animations;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.layer = this.layer && this.layer.serialize();
    res.imagePositioning = this.imagePositioning;
    res.animations = this.animations;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();

    if (this.layer.hasFile()) {
      res.layer = this.layer;
    }
    return res;
  }
}
