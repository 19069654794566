/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';

type Coordinate = { latitude: number, longitude: number, altitude?: number };

export default class TeleportItem extends BaseItem {
  coordinate: Coordinate;

  constructor(json: any) {
    if (json instanceof TeleportItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { coordinate } = json;
    this.type = ItemTypes.TeleportItem;
    this.coordinate = coordinate;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.coordinate = this.coordinate;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.coordinate = this.coordinate;
    return res;
  }
}
