/**
 * @flow
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { EventsServiceHelper } from 'src/store/events';
import { InputNumber, InputString, Loader } from 'src/pages/components';
import DatePicker from 'react-datepicker';
import * as SeasonServiceHelper from 'src/store/season/SeasonServiceHelper';
import * as Globals from 'src/constants/globals';
import { withRouter } from 'react-router-dom';

type Props = {
  editionLocale: string,
  t: (key: string) => string,
  firebase: Firebase,
  addNotif: EventsServiceHelper.addNotifType,
  locale: string,
  createSeason: SeasonServiceHelper.createSeasonType,
  history: any,
};

const NewSeasonScreen = (props: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [seasonId, setSeasonId] = React.useState<string>('');
  const [seasonName, setSeasonName] = React.useState<string>('');
  const [seasonDescription, setSeasonDescription] = React.useState<string>('');
  const [nextReleaseDate, setNextReleaseDate] = React.useState<string | null>(null);
  const [weeksBeforeNextSeasonAfterLastEnigma, setWeeksBeforeNextSeasonAfterLastEnigma] = React.useState<number>(0);

  const sendData = async () => {
    setLoading(true);
    try {
      let releaseDateTimestamp = null;
      if (nextReleaseDate) {
        releaseDateTimestamp = new Date(nextReleaseDate).getTime();
      }
      const success = await props.createSeason(
        props.firebase,
        seasonId,
        seasonName,
        seasonDescription,
        weeksBeforeNextSeasonAfterLastEnigma,
        releaseDateTimestamp,
      );
      if (success) {
        props.history.push(`/editor/season/${seasonId}`);
      }
    } catch (e) {
      console.log('Could not create season', e);
    }
    setLoading(false);
  };

  const isCreatedIdValid = seasonId && seasonId.length > 2 && seasonId.match(Globals.idRegex);

  const { t } = props;
  return (
    <div
      className="pageContainer"
      style={{ width: 'calc(100vw - 10vw)', marginRight: 'auto', marginLeft: 'auto' }}
      id="seasons-screen"
    >
      <h1>{t('screens.seasons.addSeason')}</h1>

      <div className="card bg-light screenBlock mb-3">
        <div className="card-header">
          <h3>{t('screens.newSeason.create.seasonInfoTitle')}</h3>
        </div>
        <div className="card-body">
          <InputString
            fieldName="seasonId"
            value={seasonId}
            label={t('screens.newSeason.create.idLabel')}
            handleChange={(e) => setSeasonId(e.target.value)}
          />
          <InputString
            fieldName="seasonName"
            value={seasonName}
            label={t('screens.newSeason.create.seasonName')}
            handleChange={(e) => setSeasonName(e.target.value)}
          />
          <InputString
            fieldName="seasonDescription"
            value={seasonDescription}
            label={t('screens.newSeason.create.seasonDescription')}
            handleChange={(e) => setSeasonDescription(e.target.value)}
            multiline={true}
          />
          <div className="form-group">
            <label className="strong text-capitalize">{t('screens.newSeason.create.nextReleaseDate')}</label>
            <DatePicker
              className="form-control"
              selected={nextReleaseDate}
              onChange={setNextReleaseDate}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
            />
          </div>
          <InputNumber
            fieldName="weeksBeforeNextSeasonAfterLastEnigma"
            value={weeksBeforeNextSeasonAfterLastEnigma}
            label={t('screens.newSeason.create.weeksBeforeNextSeasonAfterLastEnigma')}
            handleChange={(e) =>
              e.target.value > 0
                ? setWeeksBeforeNextSeasonAfterLastEnigma(e.target.value)
                : setWeeksBeforeNextSeasonAfterLastEnigma(0)
            }
          />

          <button
            className="btn mb-3 btn-primary"
            type="button"
            id="button-addon2"
            onClick={sendData}
            disabled={
              !seasonId ||
              !seasonName ||
              !seasonDescription ||
              !weeksBeforeNextSeasonAfterLastEnigma ||
              !isCreatedIdValid
            }
          >
            {t('general.create')}
          </button>
        </div>
      </div>

      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editionLocale: state.preferences.editionLocale,
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = { addNotif: EventsServiceHelper.addNotif, createSeason: SeasonServiceHelper.createSeason };

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
  withRouter,
)(NewSeasonScreen);
