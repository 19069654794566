/**
 * @flow
 *
 * @format
 */
import TriggeredItem, { TriggeredItemConditions } from './TriggeredItem';
import type { AppTriggeredItem, TriggeredItemConditionsType } from './TriggeredItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import LocalizedFile from './LocalizedFile';
import AtlGraphNode from './AtlGraphNode';
import type { ObjectMap } from './Shortcuts';
import type { AppAtlObject } from './AtlObject';

/* ***************************
  CLASS
*************************** */

export const ItemTypes = {
  // Since engine v1
  AMS: 'AMS',
  Anecdote: 'Anecdote',
  AnecdotePOI: 'AnecdotePOI',
  Archive: 'Archive',
  Checkpoint: 'Checkpoint',
  Comment: 'Comment',
  Custom: 'Custom',
  Discussion: 'Discussion',
  DiscussionPOI: 'DiscussionPOI',
  Document: 'Document',
  DocumentPOI: 'DocumentPOI',
  Failure: 'Failure',
  GameArea: 'GameArea',
  Openable: 'Openable',
  POI: 'POI',
  SecondaryMission: 'SecondaryMission',
  Start: 'Start',
  Success: 'Success',
  Timer: 'Timer',
  TimeTravel: 'TimeTravel',
  Tool: 'Tool',
  // Since engine v3
  Video: 'Video',
  Image360: 'Image360',
  Unlockable: 'Unlockable',
  BackgroundMusic: 'BackgroundMusic',
  BackgroundMusicControls: 'BackgroundMusicControls',
  SoundEffect: 'SoundEffect',
  TeleportItem: 'TeleportItem',
  LayerItem: 'LayerItem',
};
export type ItemTypesType = $Values<typeof ItemTypes>;
export type ItemProgressionType = { [c: TriggeredItemConditionsType]: number };
export type MetricEvent = {
  condition: TriggeredItemConditionsType,
  conditionValue?: number,
  name?: string,
};
export type LocalizedItem = ?LocalizedString | ?LocalizedFile;

export type AppBaseItem = AppAtlObject & {
  type: ItemTypesType,
  triggeredItems: TriggeredItem[],
};

export default class BaseItem extends AtlGraphNode<AppBaseItem> {
  type: ItemTypesType;

  triggeredItems: TriggeredItem[];

  clues: LocalizedStringArray;

  progression: ItemProgressionType;

  metricEvents: MetricEvent[];

  meta: any;

  unlockedValues: { [s: string]: ?number };

  triggeredStates: { [s: string]: ?string };

  constructor(json: any, isCopy: boolean = false) {
    super(json, isCopy);
    const baseContent = json || {};
    const {
      triggeredItems,
      // eslint-disable-next-line no-unused-vars
      pos,
      type,
      clues,
      progression,
      metricEvents,
      unlockedValues,
      triggeredStates,
    } = baseContent;
    this.type = type;
    if (isCopy) {
      this.triggeredItems = [...triggeredItems];
      this.unlockedValues = { ...unlockedValues };
      this.triggeredStates = { ...triggeredStates };
      this.clues = new LocalizedStringArray(`${this.id}_clues`, clues, false);
    } else {
      this.triggeredItems = this.deserizalizeTriggers(triggeredItems);
      this.unlockedValues = this.deserizalizeUnlockedValues(unlockedValues);
      this.triggeredStates = this.deserizalizeTriggeredStates(triggeredStates);
      this.clues = new LocalizedStringArray(`${this.id}_clues`, clues, false);
      if (json) {
        /* eslint-disable no-param-reassign */
        delete json.id;
        delete json.type;
        delete json.triggeredItems;
        delete json.clues;
        delete json.progression;
        delete json.metricEvents;
        /* eslint-enable no-param-reassign */
      }
    }
    this.progression = progression ? this.parseProgress(progression) : {};
    this.metricEvents = metricEvents ? this.parseMetrics(metricEvents) : [];
    if (!isCopy) {
      if (json) {
        // eslint-disable-next-line no-param-reassign
        delete json.nodeId;
      }
    }
  }

  isRemoveLocked() {
    return false;
  }

  getPolyline() {
    return [];
  }

  // eslint-disable-next-line class-methods-use-this
  parseMetrics(metrics: MetricEvent[]) {
    return metrics.map((it: MetricEvent) => {
      const res = { condition: it.condition };
      if (it.conditionValue) {
        res.conditionValue = it.conditionValue;
      }
      return res;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  parseProgress(progress: ItemProgressionType) {
    const res = {};
    Object.keys(progress).forEach((state) => {
      if (typeof progress[state] === 'string') {
        if (progress[state].length) {
          res[state] = parseInt(progress[state], 10);
        }
      } else {
        res[state] = progress[state];
      }
    });
    return res;
  }

  canReach(condition: string, conditionValue: number, usableRouteIds: string[]) {
    // TODO : may check if route makes node reachable issue #69
    let usableValues;
    if (usableRouteIds) {
      usableValues = [];
      usableRouteIds.forEach((routeId) => {
        if (this.unlockedValues[routeId] !== undefined) {
          usableValues.push(this.unlockedValues[routeId]);
        }
      });
    } else {
      usableValues = Object.values(this.unlockedValues);
    }
    Object.keys(this.unlockedValues).forEach((key) => {
      if (key.startsWith(this.nodeId)) {
        usableValues.push(this.unlockedValues[key]);
      }
    });
    if (condition === TriggeredItemConditions.Unlocked) {
      return usableValues.includes(conditionValue);
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  deserizalizeTriggers(json: any) {
    const res = [];
    if (json) {
      json.forEach((value) => {
        res.push(new TriggeredItem(value));
      });
    }
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  deserizalizeUnlockedValues(json: any) {
    if (json) {
      return json;
    }
    return {};
  }

  // eslint-disable-next-line class-methods-use-this
  deserizalizeTriggeredStates(json: any) {
    if (json) {
      const res = { ...json };
      Object.keys(res).forEach((key) => {
        if (res[key] === undefined) {
          res[key] = TriggeredItemConditions.Added;
        }
      });
      return res;
    }
    return {};
  }

  setMeta(meta: any) {
    this.meta = meta;
    if (this.meta.id) {
      delete this.meta.id;
    }
    if (this.meta.type) {
      delete this.meta.type;
    }
    if (this.meta.triggeredItems) {
      delete this.meta.triggeredItems;
    }
    if (this.meta.pos) {
      delete this.meta.pos;
    }
    if (this.meta.unlockedValues) {
      delete this.meta.unlockedValues;
    }
    if (this.meta.triggeredStates) {
      delete this.meta.triggeredStates;
    }
    if (this.meta.nodeId) {
      delete this.meta.nodeId;
    }
    if (this.meta.clues) {
      delete this.meta.clues;
    }
    if (this.meta.metricEvents) {
      delete this.meta.metricEvents;
    }
    if (this.meta.progression) {
      delete this.meta.progression;
    }
  }

  serializeMetricEvents() {
    if (this.metricEvents && this.metricEvents.length) {
      return this.metricEvents.map((it: MetricEvent) => {
        const res = {
          name: `engine_item_${it.condition.toLowerCase()}`,
          condition: it.condition,
        };
        if (it.conditionValue !== undefined) {
          res.conditionValue = it.conditionValue;
        }
        return res;
      });
    }
    return undefined;
  }

  serializeInheritedFieldsForApp() {
    const res = {
      type: this.type,
      triggeredItems: undefined,
      clues: this.clues.hasContent() ? this.clues.serialize() : undefined,
      progression: Object.keys({ ...this.progression }).length ? { ...this.progression } : undefined,
      metricEvents: this.serializeMetricEvents(),
      ...this.meta,
    };
    if (this.triggeredItems.length) {
      const resTriggers: AppTriggeredItem[] = this.triggeredItems.map((trig: TriggeredItem) => trig.serializeForApp());
      res.triggeredItems = resTriggers;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      ...super.serializeInheritedFields(),
      type: this.type,
      triggeredItems: undefined,
      nodeId: this.nodeId,
      pos: this.pos,
      unlockedValues: this.unlockedValues,
      triggeredStates: this.triggeredStates,
      clues: this.clues.hasContent() ? this.clues.serialize() : undefined,
      progression: Object.keys({ ...this.progression }).length ? { ...this.progression } : undefined,
      ...this.meta,
    };
    if (this.metricEvents.length) {
      res.metricEvents = [...this.metricEvents];
    }
    const resTriggers = this.triggeredItems.map((trig: TriggeredItem) => trig.serialize());
    res.triggeredItems = resTriggers;
    return res;
  }

  fullCheckRelease(
    items: ObjectMap<BaseItem>,
    locales: string[],
    startItemId: string,
  ): { alerts: { level: string, item: string, message: string, details?: any }[], hasProgress: boolean } {
    const alerts = this.checkRelease(items, locales, startItemId);
    const hasProgress = !!this.progression && Object.entries(this.progression).length > 0;
    return { alerts, hasProgress };
  }

  // eslint-disable-next-line class-methods-use-this
  calculateSelfUnlockedValues(startItemId?: string) {
    const res = [];
    if (this.id === startItemId) {
      res.push({ state: TriggeredItemConditions.Added });
    }
    return res;
  }

  getItemUnlockedStates(item: BaseItem) {
    if (!this.triggeredItems) {
      return [];
    }
    return this.triggeredItems
      .filter((it) => it.id === item.id)
      .map((trigger) => ({ state: trigger.newTriggeredCondition, value: trigger.newTriggeredConditionValue }));
  }

  calculateAllUnlockedValues(items: ObjectMap<BaseItem>, startItemId: string) {
    const selfUnlocked = this.calculateSelfUnlockedValues(startItemId);
    // eslint-disable-next-line no-unused-vars
    const unlockedValues = Object.entries(items).reduce((acc, [key, item]) => {
      const res = acc || [];
      if (item instanceof BaseItem) {
        /* $FlowFixMe mixed */
        res.push(...item.getItemUnlockedStates(this));
      }
      return res;
    }, []);
    /* $FlowFixMe mixed */
    return [...selfUnlocked, ...unlockedValues];
  }

  checkRelease(
    items: ObjectMap<BaseItem>,
    locales: string[],
    startItemId: string,
  ): { level: string, item: string, message: string, details?: any }[] {
    const res: { level: string, item: string, message: string, details?: any }[] = [];
    if (!this.id) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_NO_ITEM_ID',
      });
    }
    if (this.triggeredItems) {
      this.triggeredItems.forEach((trig) => {
        const trigErrors = trig.checkRelease(this, items, startItemId);
        if (trigErrors.length) {
          res.push(...trigErrors);
        }
      });
    }
    const strings = Object.values(this.getLocalizedStringsWithPath());
    strings.forEach((str) => {
      // $FlowFixMe Object.values
      const strErrors = str.checkRelease(locales);
      if (strErrors.length) {
        res.push(...strErrors);
      }
    });
    const files = this.getLocalizedFiles();
    files.forEach((file) => {
      const fileErrors = file.checkRelease(locales);
      if (fileErrors.length) {
        res.push(...fileErrors);
      }
    });
    return res;
  }

  cleanSubObjectsForFirebase(ser: any, forApp: boolean = false) {
    const res = { ...ser };
    if (this.triggeredItems.length) {
      const resTriggers: TriggeredItem[] = this.triggeredItems.map((trig: TriggeredItem) =>
        trig.serializeForFirebase(forApp),
      );
      res.triggeredItems = resTriggers;
    } else {
      delete res.triggeredItems;
    }
    return res;
  }

  // eslint-disable-next-line no-unused-vars
  getTitle(locale: string) {
    return this.id;
  }

  getGraphClass() {
    return `${this.type}_graph_class`;
  }

  getGraphTitleClass() {
    return `${this.type}_graph_title`;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'items';
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    if (this.clues && this.clues.hasContent()) {
      res.clues = this.clues;
    }
    return res;
  }
}
