/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import * as Globals from 'src/constants/globals';
import { NPCServiceHelper } from 'src/store/scenario/npcs';
import { NPC, LocalizedFile, type ObjectMap } from 'src/data';
import Loader from 'src/pages/components/loader';
import InputLocalizedFile from 'src/pages/components/inputs/InputLocalizedFile';
import { SketchPicker } from 'react-color';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Claims } from 'src/constants/roles';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

export type NpcsEditionWidgetProps = {
  updateNPCAsync: NPCServiceHelper.updateNPCAsyncType,
  removeNPC: NPCServiceHelper.removeNPCType,
  scenarioId: string,
  locale: string,
  t: (key: string) => string,
  npcs: any,
  itemsData: any,
  isConfirmedEditor: boolean,
  fileSizeLimits: ObjectMap<number>,
  addNotif: EventsServiceHelper.addNotifType,
};

type State = {
  npc?: NPC,
  role: string,
  linguisticTic: string,
  id: string,
  name: string,
  color?: string,
  picture?: LocalizedFile,
  pictureStreetView?: LocalizedFile,
  brief: string,
  birthYear?: number,
  deathYear?: number,
  isValid: boolean,
  isLoading: boolean,
  hasChanges: boolean,
};

class NpcsEditionWidget extends React.PureComponent<NpcsEditionWidgetProps, State> {
  static defaultProps = {
    scenarioId: 'unknown_',
    canChangeId: true,
    npcs: [],
    itemsData: [],
  };

  state = {
    npc: undefined,
    color: undefined,
    picture: undefined,
    pictureStreetView: undefined,
    isValid: false,
    name: '',
    role: '',
    linguisticTic: '',
    brief: '',
    id: '',
    birthYear: undefined,
    deathYear: undefined,
    isLoading: false,
    hasChanges: false,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  handleColor = (color) => {
    this.setState({ color: color.hex, hasChanges: true });
  };

  componentDidUpdate(oldProps) {
    const npcId = this.state.npc?.id;
    if (npcId) {
      const oldNpc = oldProps.npcs.find((it) => it.id === npcId);
      const curNpc = this.props.npcs.find((it) => it.id === npcId);
      if (curNpc && (!oldNpc || !curNpc.equals(oldNpc) || !this.state.picture)) {
        const { locale } = this.props;
        this.setState(
          {
            npc: curNpc,
            id: curNpc.id || '',
            name: (curNpc.name && curNpc.name.valueForLocale(locale)) || '',
            color: curNpc.color || '',
            picture: curNpc.picture,
            pictureStreetView: curNpc.pictureStreetView,
            brief: curNpc.brief || '',
            role: curNpc.role || '',
            linguisticTic: curNpc.linguisticTic || '',
            birthYear: curNpc.birthYear || undefined,
            deathYear: curNpc.deathYear || undefined,
            hasChanges: false,
          },
          () => {
            const newVal = { ...this.state };
            if (curNpc.id) this.updateValidity(newVal);
            else this.setState({ isValid: false });
          },
        );
      }
    }
  }

  componentWillUnmount() {
    const { npc } = this.state;
    if (npc && npc.id) this.saveNpc(false);
  }

  selectNpc = (data?: NPC) => {
    const { locale } = this.props;
    const { npc: oldNpc } = this.state;
    const npc = data || {};
    if (oldNpc && oldNpc.id) {
      this.saveNpc(false);
    }
    this.setState(
      {
        npc: data,
        id: npc.id || '',
        name: (npc.name && npc.name.valueForLocale(locale)) || '',
        color: npc.color || '',
        picture: npc.picture,
        pictureStreetView: npc.pictureStreetView,
        brief: npc.brief || '',
        role: npc.role || '',
        linguisticTic: npc.linguisticTic || '',
        birthYear: npc.birthYear || undefined,
        deathYear: npc.deathYear || undefined,
        hasChanges: false,
      },
      () => {
        const newVal = { ...this.state };
        if (npc.id) this.updateValidity(newVal);
        else this.setState({ isValid: false });
      },
    );
  };

  saveNpc = async (sendNotif?: boolean = true) => {
    const {
      id,
      name,
      role,
      linguisticTic,
      brief,
      npc,
      birthYear,
      deathYear,
      color,
      picture,
      pictureStreetView,
      isValid,
      hasChanges,
    } = this.state;
    if (isValid && hasChanges) {
      const { locale, updateNPCAsync, scenarioId } = this.props;
      const newNpc = new NPC(npc || {});
      newNpc.id = id;
      newNpc.role = role;
      newNpc.brief = brief;
      newNpc.linguisticTic = linguisticTic;
      newNpc.birthYear = birthYear;
      newNpc.deathYear = deathYear;
      newNpc.color = color;
      newNpc.picture = picture;
      newNpc.pictureStreetView = pictureStreetView;
      newNpc.name.setValueForLocale(name, locale);
      this.setState({ isLoading: true });
      if (updateNPCAsync) {
        try {
          await updateNPCAsync(scenarioId, id, newNpc, sendNotif);
          if (sendNotif) {
            this.selectNpc(newNpc);
          }
        } catch (error) {
          console.log('Could not update NPC', error);
        }
      }
      this.setState({ isLoading: false, hasChanges: false });
    }
  };

  removeNPC = async () => {
    const { id } = this.state;
    const { scenarioId, removeNPC, itemsData, addNotif } = this.props;
    this.setState({ isLoading: true });
    if (removeNPC) {
      try {
        await removeNPC(scenarioId, id, itemsData);
        this.selectNpc();
      } catch (error) {
        console.log('Could not remove NPC', error);
        addNotif(NotificationTypes.ERROR, 'E_CANNOT_REMOVE_NPC', error.message ?? 'Could not remove NPC', 0);
      }
    }
    this.setState({ isLoading: false });
  };

  handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.state.id, fieldName === 'pictureStreetView' ? 'illus_sv' : 'illus', oldFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  addFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.state.id, 'illus', oldFile);
    newFile.addLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    this.setState({ [fieldName]: newField, hasChanges: true });
  };

  removeFileLocale = (fieldName: string, locale: string, index = undefined) => {
    const field = index === undefined ? this.state[fieldName] : [...this.state[fieldName]];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(this.state.id, 'illus', oldFile);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }

    this.setState({ [fieldName]: newField, hasChanges: true });
    if (itemFilesToRemove.length) {
      let { filesToRemove } = this.state;
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.id.match(Globals.idRegex) && newVal.name;
    this.setState({ isValid });
  };

  renderListButton = (element) => {
    const { npc } = this.state;
    const { locale } = this.props;
    const birth = element.birthYear ? element.birthYear : '??';
    const death = element.deathYear ? element.deathYear : '??';
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (element.id === npc) {
      buttonClass += ' active';
    }
    return (
      <div className="" key={element.id}>
        <button id={element.id} className={buttonClass} onClick={() => this.selectNpc(element)}>
          <div className="d-flex justify-content-between">
            <strong className="">{element.name.valueForLocale(locale)}</strong>
            <small className="text-muted hidden">{`${birth}/${death}`}</small>
          </div>
          <p className="hidden">{element.brief}</p>
          <small className="text-muted">{element.role}</small>
        </button>
      </div>
    );
  };

  render() {
    const { npcs, t, locale, fileSizeLimits } = this.props;
    const {
      isValid,
      hasChanges,
      npc,
      name,
      role,
      brief,
      id,
      linguisticTic,
      birthYear,
      deathYear,
      isLoading,
      color,
      picture,
      pictureStreetView,
    } = this.state;
    return (
      <div className="card bg-light screenBlock npcs">
        <div className="card-header">
          <h3>{t('screens.scenarioEdition.npcEdition.sectionTitle')}</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="list-group col-4 npc-list">
              <div className="">
                <button id={'undef'} className={' btn btn-primary full-width mb-3'} onClick={this.selectNpc}>
                  {t('screens.scenarioEdition.npcEdition.newNpc')}
                </button>
              </div>
              {npcs && npcs.map((element) => this.renderListButton(element))}
            </div>
            <div className="list-group col-8">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.idPlaceholder')}
                  id="id"
                  onChange={this.handleChange}
                  value={id}
                  disabled={npc && npc.id}
                  aria-label="id"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.namePlaceholder')}
                  id="name"
                  onChange={this.handleChange}
                  value={name}
                  aria-label="name"
                  aria-describedby="basic-addon1"
                />
              </div>
              {this.props.isConfirmedEditor && (
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      {t('screens.scenarioEdition.npcEdition.colorPlaceholder')}
                    </span>
                  </div>
                  <SketchPicker color={color} onChange={this.handleColor} />
                </div>
              )}
              <InputLocalizedFile
                key={'picture'}
                fieldName={'picture'}
                value={picture}
                label={t('screens.scenarioEdition.npcEdition.picturePlaceholder')}
                disabled={!id}
                handleFileSelected={this.handleFileSelected}
                addFileLocale={this.addFileLocale}
                removeFileLocale={this.removeFileLocale}
                contentLocale={locale}
                hidden={!picture}
                accept={'.png,.jpg,.jpeg'}
                sizeWarnLimit={fileSizeLimits.pnjImage}
                sizeErrorLimit={fileSizeLimits.pnjImage}
              />
              <InputLocalizedFile
                key={'pictureStreetView'}
                fieldName={'pictureStreetView'}
                value={pictureStreetView}
                label={t('screens.scenarioEdition.npcEdition.pictureStreetViewPlaceholder')}
                disabled={!id}
                handleFileSelected={this.handleFileSelected}
                addFileLocale={this.addFileLocale}
                removeFileLocale={this.removeFileLocale}
                contentLocale={locale}
                hidden={!pictureStreetView}
                accept={'.png,.jpg,.jpeg'}
                sizeWarnLimit={fileSizeLimits.pnjImage}
                sizeErrorLimit={fileSizeLimits.pnjImage}
              />

              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.rolePlaceholder')}
                  id="role"
                  onChange={this.handleChange}
                  value={role}
                  aria-label="role"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.briefPlaceholder')}
                  id="brief"
                  onChange={this.handleChange}
                  value={brief}
                  aria-label="brief"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.ticsPlaceholder')}
                  id="linguisticTic"
                  onChange={this.handleChange}
                  value={linguisticTic}
                  aria-label="linguisticTic"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.birthPlaceholder')}
                  id="birthYear"
                  onChange={this.handleChange}
                  value={birthYear}
                  aria-label="birthYear"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-3 hidden">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1" />
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder={t('screens.scenarioEdition.npcEdition.deathPlaceholder')}
                  id="deathYear"
                  onChange={this.handleChange}
                  value={deathYear}
                  aria-label="deathYear"
                  aria-describedby="basic-addon1"
                />
              </div>
              <button
                className="btn btn-primary mb-3"
                type="button"
                id="button-addon2"
                onClick={this.saveNpc}
                disabled={!isValid || !hasChanges}
              >
                {t('general.save')}
              </button>
              {!isValid && this.state.id && !this.state.id.match(Globals.idRegex) ? (
                <p style={{ color: 'red' }}>{t('screens.scenarioEdition.npcEdition.errorId')}</p>
              ) : null}
              {npc && npc.id && isValid && (
                <>
                  <button
                    className="btn btn-outline-secondary delete mb-3"
                    type="button"
                    data-toggle="modal"
                    data-target="#confirmationModal"
                  >
                    {t('general.delete')}
                  </button>
                  <div className="modal fade" id="confirmationModal" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="confirmationModal">
                            {t('general.delete')}
                          </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">{t('screens.scenarioEdition.npcEdition.deletionModalWarning')}</div>
                        <div className="modal-footer">
                          <button className="btn btn-primary" type="button" data-dismiss="modal" aria-label="Close">
                            {t('general.cancel')}
                          </button>
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            id="button-addon2"
                            onClick={this.removeNPC}
                            disabled={!isValid}
                            data-toggle="modal"
                            data-target="#confirmationModal"
                          >
                            {t('general.delete')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  npcs: state.scenario.npcs.npcs,
  itemsData: state.scenario.items,
  scenarioId: state.scenario.header.id,
  prefix: `${state.scenario.header.id}_`,
  isConfirmedEditor: ownProps.validClaims.includes(Claims.Admin) || ownProps.validClaims.includes(Claims.Moderator),
  fileSizeLimits: state.configuration.fileSizeLimits,
  suffix: '_ams',
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  updateNPCAsync: NPCServiceHelper.updateNPCAsync,
  removeNPC: NPCServiceHelper.removeNPC,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Moderator, Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(NpcsEditionWidget);
