/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import type { AnimationHandler } from 'src/data/LayerItem';

import { withTranslation } from 'react-i18next';
import InputNumber from './InputNumber';

export interface InputAnimationsProps {
  fieldName: string;
  value: AnimationHandler[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  t: (key: string) => string;
  handleChange: (event: { target: { id: string, value: AnimationHandler[] } }) => any;
  separatorBefore?: boolean;
  inputTypes: { id: string, name: string }[];
  onFocus?: () => any;
  inputStyle?: ?any;
}

class InputAnimations extends React.Component<InputAnimationsProps> {
  static defaultProps = {
    help: undefined,
    disabled: false,
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
    inputTypes: [
      {
        id: 'opacity',
        name: 'screens.scenarioEdition.baseItemEdition.animationTypes.opacity',
      },
      {
        id: 'rotate',
        name: 'screens.scenarioEdition.baseItemEdition.animationTypes.rotate',
      },
      {
        id: 'thunder',
        name: 'screens.scenarioEdition.baseItemEdition.animationTypes.thunder',
      },
    ],
    inputStyle: {},
  };

  addAnimation = () => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value, { type: '', cycle: { values: [0], timings: [0] }, numberLoops: 0 }];
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  removeAnimation = (index: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal.splice(index, 1);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handleAnimationChanged = (index: number, indexCycle: number, newValue: number, fieldNameAnim: string) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal[index].cycle[fieldNameAnim][indexCycle] = Math.max(0, newValue);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handleTypeChange = (index: number, type: string) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal[index] = { ...value[index] };
    newVal[index].type = type;
    if (type === 'thunder') {
      // reset animation values
      newVal[index].cycle.timings = [];
      newVal[index].cycle.values = [];
      newVal[index].numberLoops = 0;
    }
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handleNumberLoopsChanged = (index: number, newNumber: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal[index] = { ...value[index] };
    newVal[index].numberLoops = Math.max(0, newNumber);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  RemoveAnimationValue = (index: number, indexCycle: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal[index].cycle.values.splice(indexCycle, 1);
    newVal[index].cycle.timings.splice(indexCycle, 1);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  addAnimationValue = (index: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: AnimationHandler[] = [...value];
    newVal[index].cycle.values.push(0);
    newVal[index].cycle.timings.push(0);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  renderSelectField = (
    fieldName: string,
    value?: string,
    label: string,
    values: { id: string, name: string }[],
    help?: ?string = undefined,
    disabled: boolean = false,
    index: number,
  ) => (
    <div className="input-group" key={fieldName}>
      <label className="input-group-prepend mr-2 text-capitalize" htmlFor={fieldName}>
        {label}
      </label>
      <select
        className="form-control"
        id={fieldName}
        onChange={(event) => this.handleTypeChange(index, event.target.value)}
        value={value}
        disabled={disabled}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
        style={this.props.inputStyle}
      >
        <option value={''}>{''}</option>
        {values &&
          values.map((element) => (
            <option key={element.id} value={element.id}>
              {this.props.t(element.name)}
            </option>
          ))}
      </select>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  render() {
    const { fieldName, value, label, help, disabled, hidden, separatorBefore, onFocus, t } = this.props;
    return (
      <div className="form-group lock pb-2" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="strong text-capitalize">
          {`${label} `}
        </label>
        <button type="button" className="btn btn-primary btn-sm ml-2 mb-1 add" onClick={this.addAnimation}>
          {t('general.add')}
        </button>
        {value &&
          value.map((val, index) => (
            <div className="card pt-0" key={`${fieldName}_${index}`}>
              <div className="card-body mt-0">
                {this.renderSelectField(
                  'type',
                  val.type,
                  t('general.animationType'),
                  this.props.inputTypes.filter(
                    (type) => type.id === val.type || !value.map((anim) => anim.type).includes(type.id),
                  ),
                  undefined,
                  disabled,
                  index,
                )}
                {val.type !== 'thunder' ? (
                  <div>
                    <InputNumber
                      key={`${val.id}-${index}-numberLoops`}
                      fieldName="values"
                      onFocus={onFocus}
                      value={val.numberLoops || 0}
                      label={this.props.t('screens.scenarioEdition.baseItemEdition.numberLoopsLabel')}
                      handleChange={(event) => this.handleNumberLoopsChanged(index, event.target.value)}
                      inputStyle={this.props.inputStyle}
                      inlineTitle
                      isCoordinates={false}
                    />
                    <label htmlFor={fieldName} className="strong text-capitalize">
                      {this.props.t('screens.scenarioEdition.baseItemEdition.animationValuesLabel')}
                    </label>
                    {val.cycle.values.map((value, indexCycle) => (
                      <InputNumber
                        style={{ marginBottom: '-10px', marginTop: '0px' }}
                        key={`${val.id}-${index}-values-${indexCycle}`}
                        fieldName="values"
                        onFocus={onFocus}
                        value={value}
                        handleChange={(event) =>
                          this.handleAnimationChanged(index, indexCycle, event.target.value, 'values')
                        }
                        inputStyle={this.props.inputStyle}
                        inlineTitle
                        isCoordinates={false}
                        prependStyle={{ border: 'none' }}
                        suffix={
                          <button
                            className="btn delete btn-sm m-0 p-0 pr-2 pl-2"
                            type="button"
                            onClick={() => this.RemoveAnimationValue(index, indexCycle)}
                            disabled={val.cycle.values.length <= 1}
                          >
                            X
                          </button>
                        }
                      />
                    ))}
                    <label htmlFor={fieldName} className="strong text-capitalize mt-2">
                      {this.props.t('screens.scenarioEdition.baseItemEdition.animationTimingsLabel')}
                    </label>
                    {val.cycle.timings.map((timing, indexCycle) => (
                      <div key={`${val.id}-${index}-values-${indexCycle}`}>
                        <InputNumber
                          style={{ marginBottom: '-10px', marginTop: '0px' }}
                          fieldName="timings"
                          onFocus={onFocus}
                          value={timing}
                          handleChange={(event) =>
                            this.handleAnimationChanged(index, indexCycle, event.target.value, 'timings')
                          }
                          inputStyle={this.props.inputStyle}
                          inlineTitle
                          isCoordinates={false}
                          prependStyle={{ border: 'none' }}
                          suffix={
                            <button
                              className="btn delete btn-sm m-0 p-0 pr-2 pl-2"
                              type="button"
                              onClick={() => this.RemoveAnimationValue(index, indexCycle)}
                              disabled={val.cycle.timings.length <= 1}
                            >
                              X
                            </button>
                          }
                        />
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-primary btn-sm mb-4 mt-0"
                      onClick={() => this.addAnimationValue(index)}
                    >
                      {t('general.add')}
                    </button>
                  </div>
                ) : null}
                <button
                  type="button"
                  className="btn btn-primary btn-sm delete mt-2"
                  onClick={() => this.removeAnimation(index)}
                >
                  {t('general.delete')}
                </button>
              </div>
            </div>
          ))}
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
export default withTranslation('default')(InputAnimations);
