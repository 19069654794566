/**
 * @flow
 *
 * @format
 */
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import AtlObject from './AtlObject';
import type { AppAtlObject } from './AtlObject';
import LocalizedFile from './LocalizedFile';

export type AppNPC = AppAtlObject & {
  name?: LocalizedString,
};

export default class NPC extends AtlObject<AppNPC> {
  name: LocalizedString;

  role: string;

  brief: ?string;

  linguisticTic: ?string;

  color: ?string;

  picture: ?LocalizedFile;

  pictureStreetView: ?LocalizedFile;

  birthYear: ?number;

  deathYear: ?number;

  constructor(json: any) {
    super(json);
    this.name = new LocalizedString(`${this.id}_name`, json.name);
    this.role = json.role;
    this.brief = json.brief;
    this.linguisticTic = json.linguisticTic;
    this.color = json.color;
    this.picture = new LocalizedFile(this.id, 'picture', json.picture);
    this.pictureStreetView = new LocalizedFile(this.id, 'pictureStreetView', json.pictureStreetView);
    this.birthYear = json.birthYear;
    this.deathYear = json.deathYear;
  }

  equals(elem: any) {
    if (!(elem instanceof NPC)) {
      return false;
    }
    if (
      elem.role !== this.role ||
      elem.brief !== this.brief ||
      elem.linguisticTic !== this.linguisticTic ||
      elem.color !== this.color ||
      elem.birthYear !== this.birthYear ||
      elem.deathYear !== this.deathYear
    ) {
      return false;
    }
    if (JSON.stringify(this.name.serializeForApp()) !== JSON.stringify(elem.name.serializeForApp())) {
      return false;
    }
    if (JSON.stringify(this.picture?.serializeForApp()) !== JSON.stringify(elem.picture?.serializeForApp())) {
      return false;
    }

    if (
      JSON.stringify(this.pictureStreetView?.serializeForApp()) !==
      JSON.stringify(elem.pictureStreetView?.serializeForApp())
    ) {
      return false;
    }

    return true;
  }

  update(json: any) {
    if (json.name) {
      this.name = json.name;
    }
    if (json.role) {
      this.role = json.role;
    }
    if (json.brief) {
      this.brief = json.brief;
    }
    if (json.linguisticTic) {
      this.linguisticTic = json.linguisticTic;
    }
    if (json.birthYear) {
      this.birthYear = json.birthYear;
    }
    if (json.deathYear) {
      this.deathYear = json.deathYear;
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      name: this.name ? this.name.serializeForApp() : undefined,
      color: this.color || undefined,
    };
    if (this.picture && this.picture.hasFile()) {
      res.picture = this.picture.serializeForApp();
    }
    if (this.pictureStreetView && this.pictureStreetView.hasFile()) {
      res.pictureStreetView = this.pictureStreetView.serializeForApp();
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      name: this.name ? this.name.serialize() : undefined,
      color: this.color,
      role: this.role,
      brief: this.brief,
      linguisticTic: this.linguisticTic,
      birthYear: this.birthYear,
      deathYear: this.deathYear,
    };
    res.picture = this.picture && this.picture.serialize();
    res.pictureStreetView = this.pictureStreetView && this.pictureStreetView.serialize();
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();

    if (this.picture?.hasFile()) {
      res.picture = this.picture;
    }
    if (this.pictureStreetView?.hasFile()) {
      res.pictureStreetView = this.pictureStreetView;
    }
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    return res;
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationCsvIdPrefix() {
    return 'npcs';
  }
}
