/**
 * @format
 * @flow
 */

import React from 'react';
import logo from 'src/assets/logo ATLANTIDE.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { Scenario } from 'src/data';

type ErrorBoundaryProps = {
  firebase: Firebase,
  notifyEditorError: ConfigurationServiceHelper.notifyEditorErrorType,
  user: any,
  scenario: Scenario,
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    if (!process.env.NODE_ENV) {
      this.props.notifyEditorError(this.props.firebase, this.props.user.uid, this.props.scenario.id, {
        message: error?.message,
        errorInfo,
      });
    }
  }

  refreshPage = () => {
    window.location.reload(false);
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="">
          <div className="pageContainer" style={{ width: '100%' }}>
            <div className="page-error active" logo={logo} style={{ width: '100%' }}>
              <div className="page-error-content">
                <FontAwesomeIcon icon={['fad', 'heart-broken']} className="icon" />
                <h2>Une erreur est survenue</h2>
                <p>Aidez-nous à stabiliser le studio en nous indiquant ce qui vous est arrivé </p>
                <a
                  href="mailto:contact@eritaj.studio?subject=[AS-BETA] Le studio a planté"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  👉 REPORTER UN BUG
                </a>
                <hr />
                <p style={{ marginBottom: 0 }}>>🛠 Pour reprendre l'édition du scénario</p>
                <button className="page-error-refresh" onClick={this.refreshPage}>
                  Rafraichissez votre page
                </button>{' '}
              </div>
            </div>
          </div>
        </div>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  user: state.user.user,
  scenario: state.scenario.header,
});
const mapDispatchToProps = {
  notifyEditorError: ConfigurationServiceHelper.notifyEditorError,
};

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(ErrorBoundary);
