/**
 * @flow
 *
 * @format
 */
import { Singleton, LocaleDefaults } from 'src/store/scenario/header/ScenarioLocaleManager';
import type { ObjectMap } from './Shortcuts';

export const cleanupString = (value?: string = '') => {
  const quoteReplacer = (correspondance, p1) => `“${p1}”`;
  if (typeof value === 'string') {
    let res = value.trim();
    res = res.replace(/[ ]*\\n[ ]*/g, '\n');
    res = res.replace(/"([^"]*)"/, quoteReplacer);
    return res;
  }
  return value;
};

export const getValueForLocale = () => {};
export default class LocalizedString {
  id: string;

  defaultLocale: string;

  translate: boolean;

  values: ObjectMap<string>; // locale/value

  constructor(id: string, values: any = {}, translate: boolean = true) {
    const { defaultLocale, defaultValues } = Singleton.localizedStringParams(translate);
    this.id = id;
    this.translate = translate;
    let valuesToUse = values;
    if (values.values) {
      valuesToUse = values.values;
    }
    const cleanedValues = {};
    Object.keys(valuesToUse).forEach((locale) => (cleanedValues[locale] = cleanupString(valuesToUse[locale])));
    this.defaultLocale = defaultLocale;
    this.values = { ...defaultValues, ...cleanedValues };
  }

  addLocale(locale: string, value?: string) {
    this.values[locale] = cleanupString(value) || LocaleDefaults[locale];
  }

  flush() {
    Object.keys(this.values).forEach((locale) => {
      this.values[locale] = '';
    });
  }

  removeLocale(locale: string) {
    delete this.values[locale];
  }

  hasLocale(locale: string) {
    return this.values[locale];
  }

  valueForLocale(locale: string, defaultIfTrue: boolean = false): string {
    const val = this.values[locale];
    if (val || !defaultIfTrue) {
      return val;
    }
    return this.values[this.defaultLocale];
  }

  setValueForLocale(value: string, locale: string) {
    this.values[locale] = cleanupString(value);
  }

  isEmpty() {
    return (
      !Object.keys(this.values).length ||
      /* $FlowFixMe: Object.values */
      Object.values(this.values).reduce((acc, cur: string) => acc || !cur || cur.length, false) === 0
    );
  }

  requireTranslations() {
    const firstKey = Object.keys(this.values)[0];
    return Object.keys(this.values).length > 1 || (firstKey && firstKey !== 'default');
  }

  serializeForApp() {
    const res = { ...this.values };
    return res;
  }

  serialize() {
    const res = { ...this.values };
    return res;
  }

  checkRelease(locales: string[]): { level: string, item: string, message: string, details?: any }[] {
    const res = [];
    // Check that the content is available in all locales
    if (!this.hasLocale('default')) {
      locales.forEach((loc) => {
        if (loc !== 'default' && !this.hasLocale(loc)) {
          res.push({
            level: 'warn',
            item: this.id,
            message: 'E_STRING_MISSING_IN_LOCALE',
            details: { message: `No translation for ${this.id} in ${loc}` },
          });
        }
      });
    }
    return res;
  }

  applyTranslations(translationObj: { [locale: string]: string }) {
    const cleanedTranslations = {};
    Object.keys(translationObj).forEach((locale) => {
      cleanedTranslations[locale] = cleanupString(translationObj[locale]);
    });
    if (!this.translate) {
      Object.keys(cleanedTranslations).forEach((locale) => {
        if (!cleanedTranslations[locale] || !cleanedTranslations[locale].length) {
          delete cleanedTranslations[locale];
        }
      });
    }
    if (translationObj) {
      this.values = { ...this.values, ...cleanedTranslations };
    }
  }

  getCSVContent() {
    return { ...this.values };
  }
}
