/**
 * @flow
 *
 * @format
 */

import type { Seasons } from 'src/data/types/Seasons';
import React from 'react';
import { Colors } from 'src/assets';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

type Props = {
  currentSeason: Seasons,
  locale: string,
  t: (key: string) => string,
  history: any,
};

const CurrentSeasonCard = (props: Props) => {
  const { t } = props;
  let background = props.currentSeason.backgroundImage?.files?.default?.url;
  background = `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), ${
    background ? `url(${background})` : ''
  } center / cover no-repeat`;
  return (
    <div
      style={{
        background,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        margin: 'auto',
        color: Colors.white,
      }}
      className="py-4 px-5 scenarioButton"
    >
      <h1>{t('screens.seasons.currentSeason')}</h1>
      <h2>{props.currentSeason.seasonName}</h2>
      <p>{props.currentSeason.seasonDescription}</p>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>
          {t('screens.seasons.nextRelease')}: {moment(props.currentSeason.nextReleaseDate).format('DD/MM/YYYY')}
        </p>
        {props.currentSeason.nextSeasonId && (
          <button
            className="btn btn-primary"
            style={{ zIndex: 100 }}
            type="button"
            id="button-save"
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/editor/season/${props.currentSeason.nextSeasonId}/edit`);
            }}
          >
            {t('screens.seasons.nextSeason')}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(CurrentSeasonCard);
