/**
 * @flow
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { EventsServiceHelper } from 'src/store/events';
import type { Seasons, SeasonsManagement } from 'src/data/types/Seasons';
import { InputSelect, Loader } from 'src/pages/components';
import SeasonCard from 'src/pages/seasons/components/SeasonCard';
import CurrentSeasonCard from 'src/pages/seasons/components/CurrentSeasonCard';
import { withRouter } from 'react-router-dom';
import * as SeasonServiceHelper from 'src/store/season/SeasonServiceHelper';

type Props = {
  editionLocale: string,
  t: (key: string) => string,
  firebase: Firebase,
  addNotif: EventsServiceHelper.addNotifType,
  history: any,
  getAllSeasons: SeasonServiceHelper.getAllSeasonsType,
};

const SeasonsScreen = (props: Props) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [seasons, setSeasons] = React.useState<SeasonsManagement | null>(null);
  const [currentSeason, setCurrentSeason] = React.useState<Seasons | null>(null);
  const [endedSeasons, setEndedSeasons] = React.useState<Array<Seasons | null>>([]);
  const [draftSeasons, setDraftSeasons] = React.useState<Array<Seasons | null>>([]);
  const [allSeasons, setAllSeasons] = React.useState<Array<Seasons | null>>([]);
  const [selectedSeasonType, setSelectedSeasonType] = React.useState<string>('all');

  const getSeasons = async () => {
    setLoading(true);
    try {
      const seasonsResult = await props.getAllSeasons(props.firebase);
      if (seasonsResult) {
        const draft = Object.values(seasonsResult.draftSeasons || {}) ?? [];
        const ended = Object.values(seasonsResult.endedSeasons || {}) ?? [];
        seasonsResult.draftSeasons = draft;
        seasonsResult.endedSeasons = ended;

        setAllSeasons(draft.concat(ended));
        setSeasons(seasonsResult);
        setCurrentSeason(seasonsResult.currentSeason ?? null);
        setDraftSeasons(draft);
        setEndedSeasons(ended);
      }
    } catch (error) {
      console.warn('Cannot retrieve seasons', error);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!seasons && loading) {
      getSeasons().then();
    }
  }, [seasons, loading]);

  React.useEffect(() => {
    switch (selectedSeasonType) {
      case 'draft':
        setAllSeasons(seasons.draftSeasons);
        break;
      case 'ended':
        setAllSeasons(seasons.endedSeasons);
        break;
      default:
        setAllSeasons(draftSeasons.concat(endedSeasons));
    }
  }, [selectedSeasonType]);

  const seasonTypes = ['all', 'draft', 'ended'];

  const { t } = props;
  return (
    <div
      className="pageContainer"
      style={{ width: 'calc(100vw - 10vw)', marginRight: 'auto', marginLeft: 'auto' }}
      id="seasons-screen"
    >
      <h1>{t('screens.seasons.title')}</h1>
      <button
        className="btn btn-primary"
        type="button"
        id="button-save"
        onClick={() => props.history.push('/editor/season/new')}
      >
        {t('screens.seasons.addSeason')}
      </button>
      {currentSeason && (
        <div className="row mt-4" onClick={() => props.history.push(`/editor/season/${currentSeason.seasonId}`)}>
          <CurrentSeasonCard currentSeason={currentSeason} />
        </div>
      )}

      <div className="mt-4 pt-4">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <InputSelect
            fieldName="selectedSeasonType"
            value={selectedSeasonType}
            values={seasonTypes}
            handleChange={(e) => setSelectedSeasonType(e.target.value)}
            itemToId={(it) => it}
            itemToTitle={(it) => {
              return t(`screens.seasons.seasonTypes.${it.toLowerCase()}`);
            }}
            label={t('screens.seasons.seasonTypes.title')}
            className="mr-5"
          />
        </div>

        <div className="row mt-4">
          {allSeasons.length
            ? allSeasons.map((season, s) => {
                return (
                  <SeasonCard
                    season={season}
                    onClick={() => props.history.push(`/editor/season/${season.seasonId}`)}
                    key={s}
                  />
                );
              })
            : ''}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editionLocale: state.preferences.editionLocale,
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = { addNotif: EventsServiceHelper.addNotif, getAllSeasons: SeasonServiceHelper.getAllSeasons };

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
  withRouter,
)(SeasonsScreen);
