/**
 * @flow
 *
 * @format
 */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
import type { ReduxDispatch } from 'redux';

import Firebase, { FirebaseHelper } from 'src/services/Firebase';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { asyncForEach } from 'src/utils';
import type { SeasonAdventure, Seasons } from 'src/data/types/Seasons';
import { LocalizedFile } from 'src/data';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# ScenarioServiceHelper / ${title}`, args);
  }
};

export type getAllSeasonsType = (firebase: Firebase) => (ReduxDispatch) => Promise<any>;
export const getAllSeasons: getAllSeasonsType = (firebase) => async (dispatch) => {
  try {
    const result = await firebase.getAllSeasonsAsync();
    return result;
  } catch (error) {
    console.log('Could not create season', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export type getDraftSeasonsType = (firebase: Firebase) => (ReduxDispatch) => Promise<any>;
export const getDraftSeasons: getDraftSeasonsType = (firebase) => async (dispatch) => {
  try {
    const result = await firebase.getDraftSeasonsAsync();
    return result;
  } catch (error) {
    console.log('Could not get draftSeasons', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export type getSeasonByIdType = (firebase: Firebase, seasonId: string) => (ReduxDispatch) => Promise<Seasons>;
export const getSeasonById: getSeasonByIdType = (firebase, seasonId) => async (dispatch) => {
  try {
    const result = await firebase.getSeasonByIdAsync(seasonId);
    return result;
  } catch (error) {
    console.log('Could not create season', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export type saveSeasonType = (
  firebase: Firebase,
  seasonId: string,
  seasonName: string,
  seasonDescription: string,
  backgroundImage?: LocalizedFile,
  nextReleaseDate?: number,
  weeksBeforeNextSeasonAfterLastEnigma: number,
  nextSeasonId?: string,
  seasonScenarios?: Array<SeasonAdventure>,
) => (ReduxDispatch) => Promise<Seasons>;
export const saveSeason: saveSeasonType = (
  firebase,
  seasonId,
  seasonName,
  seasonDescription,
  backgroundImage,
  nextReleaseDate,
  weeksBeforeNextSeasonAfterLastEnigma,
  nextSeasonId,
  seasonScenarios,
) => async (dispatch) => {
  logHelperCall('saveSeason', { seasonId });
  try {
    if (backgroundImage) {
      const shouldUpload = backgroundImage.hasFileToUpload();
      if (shouldUpload) {
        await asyncForEach(Object.keys(backgroundImage.files), async (locale) => {
          const file = backgroundImage.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushSeasonAssetAsync(
              seasonId,
              backgroundImage.getStorageFileName(locale, 'v1', ext),
              file.contentToUpload,
              firebase,
            );
            // eslint-disable-next-line no-param-reassign
            backgroundImage.ext = ext;
            file.name = backgroundImage.getStorageFileName(locale, 'v1', ext);
            file.version = 'v1';
            file.url = url;
            file.ext = ext;
            delete file.contentToUpload;
          }
        });
      }
    }
    const result = await firebase.saveSeasonAsync(
      seasonId,
      seasonName,
      seasonDescription,
      backgroundImage,
      weeksBeforeNextSeasonAfterLastEnigma,
      nextReleaseDate,
      nextSeasonId,
      seasonScenarios,
    );
    return result;
  } catch (error) {
    console.log('Could not save season', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};

export type createSeasonType = (
  seasonId: string,
  seasonName: string,
  seasonDescription: string,
  nextReleaseDate?: number,
  weeksBeforeNextSeasonAfterLastEnigma: number,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<string>;
export const createSeason: createSeasonType = (
  firebase,
  seasonId,
  seasonName,
  seasonDescription,
  weeksBeforeNextSeasonAfterLastEnigma,
  nextReleaseDate,
) => async (dispatch) => {
  logHelperCall('createSeason', { seasonName });
  try {
    await firebase.createSeasonAsync(
      seasonId,
      seasonName,
      seasonDescription,
      weeksBeforeNextSeasonAfterLastEnigma,
      nextReleaseDate,
    );
    return true;
  } catch (error) {
    console.log('Could not create season', error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return false;
  }
};
