/**
 * @flow
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { EventsServiceHelper } from 'src/store/events';
import type { Seasons } from 'src/data/types/Seasons';
import { InputLocalizedFile, InputNumber, InputSelect, InputString, Loader } from 'src/pages/components';
import { withRouter } from 'react-router-dom';
import * as SeasonServiceHelper from 'src/store/season/SeasonServiceHelper';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import { LocalizedFile, Scenario } from 'src/data';
import InputSeasonScenarios from 'src/pages/components/inputs/InputSeasonScenarios';
import { inputSeasonScenariosToSeasonScenarios, seasonScenariosToInputSeasonScenarios } from 'src/utils/seasons';

type Props = {
  editionLocale: string,
  t: (key: string) => string,
  firebase: Firebase,
  addNotif: EventsServiceHelper.addNotifType,
  history: any,
  getSeasonById: SeasonServiceHelper.getSeasonByIdType,
  getDraftSeasons: SeasonServiceHelper.getDraftSeasonsType,
  saveSeason: SeasonServiceHelper.saveSeasonType,
  locale: string,
};

const EditSeasonScreen = (props: Props) => {
  const { seasonId } = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [season, setSeason] = React.useState<Seasons>(null);
  const [draftSeasons, setDraftSeasons] = React.useState<Array<Seasons>>([]);
  const [scenarios, setScenarios] = React.useState<Array<Scenario>>([]);
  const [actualSeasonId, setActualSeasonId] = React.useState<string>('');
  const [seasonName, setSeasonName] = React.useState<string>('');
  const [seasonDescription, setSeasonDescription] = React.useState<string>('');
  const [nextReleaseDate, setNextReleaseDate] = React.useState<string | null>(null);
  const [weeksBeforeNextSeasonAfterLastEnigma, setWeeksBeforeNextSeasonAfterLastEnigma] = React.useState<number>(0);
  const [backgroundImage, setBackgroundImage] = React.useState<LocalizedFile>();
  const [filesToRemove, setFilesToRemove] = React.useState<any[]>([]);
  const [nextSeasonId, setNextSeasonId] = React.useState<string>(null);
  const [seasonScenarios, setSeasonScenarios] = React.useState();

  const getSeason = async () => {
    setLoading(true);
    try {
      const seasonResult = await props.getSeasonById(props.firebase, seasonId);
      setSeason(seasonResult);
      setBackgroundImage(new LocalizedFile('backgroundImage', 'backgroundImage', seasonResult.backgroundImage, true));
      setActualSeasonId(seasonId);
      setSeasonName(seasonResult.seasonName);
      setSeasonDescription(seasonResult.seasonDescription);
      setNextReleaseDate(seasonResult.nextReleaseDate ? new Date(seasonResult.nextReleaseDate) : undefined);
      setWeeksBeforeNextSeasonAfterLastEnigma(seasonResult.weeksBeforeNextSeasonAfterLastEnigma);
      setNextSeasonId(seasonResult.nextSeasonId);
      setSeasonScenarios(seasonScenariosToInputSeasonScenarios(seasonResult.seasonScenarios));
      const draftSeasonsResult = await props.getDraftSeasons(props.firebase);
      setDraftSeasons(draftSeasonsResult || []);

      const scenariosSnap = await props.firebase.prodReleases().once('value');
      if (scenariosSnap.exists()) {
        let val = scenariosSnap.val();
        val = Object.values(val);
        setScenarios(val);
      }
    } catch (error) {
      console.warn('Cannot retrieve season', error);
    }
    setLoading(false);
  };

  const saveSeason = async () => {
    setLoading(true);
    try {
      const convertedSeasonScenarios = inputSeasonScenariosToSeasonScenarios(seasonScenarios);

      if (filesToRemove && filesToRemove.length) {
        await FirebaseHelper.removeEditorFilesAsync(seasonId, filesToRemove, 'season', props.firebase);
      }

      const seasonResult = await props.saveSeason(
        props.firebase,
        seasonId,
        seasonName,
        seasonDescription,
        backgroundImage,
        nextReleaseDate.getTime(),
        weeksBeforeNextSeasonAfterLastEnigma,
        nextSeasonId,
        convertedSeasonScenarios,
      );
      setSeason(seasonResult);
    } catch (error) {
      console.warn('Cannot save season', error);
    }
    setLoading(false);
  };

  const handleChangeFiles = (event) => {
    const { value } = event.target;
    setBackgroundImage(value);
  };

  const handleFileSelected = (fieldName: string, locale: string, file: File, index: ?number = undefined) => {
    const field = index === undefined ? backgroundImage : [...backgroundImage];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(fieldName, fieldName, oldFile, true);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    if (!newFile.hasLocale(locale)) {
      newFile.addLocale(locale);
    }
    newFile.files[locale].contentToUpload = file;
    newFile.files[locale].name = file.name;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    setBackgroundImage(newField);
    if (itemFilesToRemove.length) {
      let newFilesToRemove = filesToRemove;
      if (newFilesToRemove) {
        newFilesToRemove = [...newFilesToRemove, ...itemFilesToRemove];
      } else {
        newFilesToRemove = itemFilesToRemove;
      }
      setFilesToRemove(newFilesToRemove);
    }
  };

  const addFile = (fieldName) => () => {
    const field = [...backgroundImage];
    const max = field.reduce((acc, cur) => (cur.index >= acc ? cur.index + 1 : acc), 0);
    field.push(new LocalizedFile(fieldName, fieldName, { index: max }, true));
    setBackgroundImage(field);
  };

  const removeFile = (fieldName, index) => {
    let field = [...backgroundImage];
    const fileToRemove = field.find((it) => it.index === index);
    field = field.filter((it) => it.index !== index);

    setBackgroundImage(field);
    const itemFilesToRemove = fileToRemove.listStorageFiles();
    if (itemFilesToRemove.length) {
      let newFilesToRemove = filesToRemove;
      if (newFilesToRemove) {
        newFilesToRemove = [...newFilesToRemove, ...itemFilesToRemove];
      } else {
        newFilesToRemove = itemFilesToRemove;
      }
      setFilesToRemove(newFilesToRemove);
    }
  };

  const addFileLocale = (fieldName: string, locale: string) => {
    const field = backgroundImage;
    const oldFile = field;
    const newFile = new LocalizedFile(fieldName, fieldName, oldFile, true);
    newFile.addLocale(locale);
    const newField = newFile;
    setBackgroundImage(newField);
  };

  const removeFileLocale = (fieldName: string, locale: string) => {
    const field = backgroundImage;
    const oldFile = field;
    const newFile = new LocalizedFile(fieldName, fieldName, oldFile, true);
    const itemFilesToRemove = newFile.listStorageFiles(locale);
    newFile.removeLocale(locale);
    const newField = newFile;

    setBackgroundImage(newField);
    if (itemFilesToRemove.length) {
      let newFilesToRemove = filesToRemove;
      if (newFilesToRemove) {
        newFilesToRemove = [...newFilesToRemove, ...itemFilesToRemove];
      } else {
        newFilesToRemove = itemFilesToRemove;
      }
      setFilesToRemove(newFilesToRemove);
    }
  };

  const handleContentChange = (fieldName: string, value: string, index: ?number = undefined) => {
    const field = index === undefined ? backgroundImage : [...backgroundImage];
    let oldFile;
    if (index !== undefined) {
      oldFile = field.find((it) => it.index === index);
    } else {
      oldFile = field;
    }
    const newFile = new LocalizedFile(fieldName, fieldName, oldFile, true);
    newFile.content.values.fr = value;
    let newField;
    if (index !== undefined) {
      newField = field.map((it) => {
        if (it.index === index) {
          return newFile;
        }
        return it;
      });
    } else {
      newField = newFile;
    }
    setBackgroundImage(newField);
  };

  React.useEffect(() => {
    if (!season && loading) {
      getSeason().then();
    }
  }, [season, loading]);

  const { t } = props;
  return (
    <div
      className="pageContainer"
      style={{ width: 'calc(100vw - 10vw)', marginRight: 'auto', marginLeft: 'auto' }}
      id="seasons-screen"
    >
      <h1>{t('screens.editSeason.title')}</h1>

      <div className="card bg-light screenBlock mb-3">
        <div className="card-header">
          <h3>{t('screens.newSeason.create.seasonInfoTitle')}</h3>
        </div>
        <div className="card-body">
          <InputString
            fieldName="seasonId"
            value={actualSeasonId}
            label={t('screens.newSeason.create.idLabel')}
            handleChange={() => {}}
            disabled={true}
          />
          <InputString
            fieldName="seasonName"
            value={seasonName}
            label={t('screens.newSeason.create.seasonName')}
            handleChange={(e) => setSeasonName(e.target.value)}
          />
          <InputString
            fieldName="seasonDescription"
            value={seasonDescription}
            label={t('screens.newSeason.create.seasonDescription')}
            handleChange={(e) => setSeasonDescription(e.target.value)}
            multiline={true}
          />
          <div className="form-group">
            <label className="strong text-capitalize">{t('screens.newSeason.create.nextReleaseDate')}</label>
            <DatePicker
              className="form-control"
              selected={nextReleaseDate}
              onChange={setNextReleaseDate}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
            />
          </div>
          <InputNumber
            fieldName="weeksBeforeNextSeasonAfterLastEnigma"
            value={weeksBeforeNextSeasonAfterLastEnigma}
            label={t('screens.newSeason.create.weeksBeforeNextSeasonAfterLastEnigma')}
            handleChange={(e) =>
              e.target.value > 0
                ? setWeeksBeforeNextSeasonAfterLastEnigma(e.target.value)
                : setWeeksBeforeNextSeasonAfterLastEnigma(0)
            }
          />

          <InputLocalizedFile
            fieldName="backgroundImage"
            value={backgroundImage}
            label={t('screens.newSeason.create.backgroundImage')}
            handleChange={handleChangeFiles}
            handleFileSelected={handleFileSelected}
            handleContentChange={handleContentChange}
            addFileLocale={addFileLocale}
            removeFileLocale={removeFileLocale}
            contentLocale="fr" // Only fr for now
            addFile={addFile('backgroundImage')}
            removeFile={removeFile}
            sizeWarnLimit={500000}
            sizeErrorLimit={5000000}
          />
          <button
            className="btn mb-3 btn-primary"
            type="button"
            id="button-addon2"
            onClick={() => saveSeason()}
            disabled={!seasonId || !seasonName || !seasonDescription || !weeksBeforeNextSeasonAfterLastEnigma}
          >
            {t('general.edit')}
          </button>
        </div>
      </div>

      <div className="card bg-light screenBlock mb-3">
        <div className="card-header">
          <h3>{t('screens.editSeason.edit.seasonAdditionalInfoTitle')}</h3>
        </div>
        <div className="card-body">
          <InputSelect
            fieldName="nextSeasonId"
            value={nextSeasonId}
            values={draftSeasons.filter((season) => season.seasonId !== seasonId)}
            handleChange={(e) => setNextSeasonId(e.target.value)}
            itemToId={(it) => it.seasonId}
            itemToTitle={(it) => it.seasonName}
            label={t('screens.editSeason.edit.nextSeasonId')}
            className="mr-5"
          />

          <InputSeasonScenarios
            key="seasonScenarios"
            className="pb2"
            separatorBefore={true}
            fieldName="seasonScenarios"
            value={seasonScenarios}
            label={t('screens.editSeason.edit.seasonScenarios')}
            disabled={!scenarios.length}
            handleChange={(e) => setSeasonScenarios(e.target.value)}
            inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            availableValues={scenarios}
            locale={props.locale}
          />

          <button className="btn mb-3 btn-primary" type="button" id="button-addon2" onClick={() => saveSeason()}>
            {t('general.edit')}
          </button>
        </div>
      </div>

      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editionLocale: state.preferences.editionLocale,
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  addNotif: EventsServiceHelper.addNotif,
  getSeasonById: SeasonServiceHelper.getSeasonById,
  getDraftSeasons: SeasonServiceHelper.getDraftSeasons,
  saveSeason: SeasonServiceHelper.saveSeason,
};

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
  withRouter,
)(EditSeasonScreen);
