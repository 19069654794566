/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { BaseItem, ItemTypes, POIItem } from 'src/data';
import { MapEditionWidget } from 'src/pages/components';
import { withModal } from 'src/pages/components/modals';
import { FirebaseSingleton } from 'src/services/Firebase';
import type { ScenarioReducerState } from 'src/store/scenario';

type Props = {
  onClick: (info: any) => any,
  info: any,
  locale: string,
  isActive: boolean,
  addError: (errors?: any[], item?: string) => any,
  items: ScenarioReducerState,
};

const MapReview = (props: Props) => {
  const [currentItem, setCurrentItem] = useState(undefined);
  const [configuredErrors, setConfiguredErrors] = useState('');
  const { t } = props;

  const { items } = props;

  const getPreconfiguredErrors = async () => {
    try {
      const configuredErrorsSnap = await FirebaseSingleton.mapSubmissionErrors().once('value');
      const errors = configuredErrorsSnap.val();
      setConfiguredErrors(Object.entries(errors).map(([key, value]) => ({ key, value })));
    } catch (error) {
      // nothing
      console.error('BOUM', error);
    }
  };
  useEffect(() => {
    getPreconfiguredErrors();
  }, []);

  const selectItem = (id?: string) => {
    setCurrentItem(id);
  };

  const addError = () => {
    props.addError(configuredErrors, currentItem);
  };

  return (
    <div className="card mt-2">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.mapReview')}</h5>
      </div>
      <div className="card-body">
        <div className="container-fluid fill background-primary" id="graphContainer">
          <button className="btn btn-outline-warning ml-2" type="button" id="button-deploy" onClick={addError}>
            {t('screens.review.configureError')}
          </button>
          <MapEditionWidget
            currentItemId={currentItem}
            itemClicked={selectItem}
            itemsMap={items}
            markerFilter={(it) => it instanceof BaseItem && it.type === ItemTypes.POI}
            polygonFilter={(it) => it instanceof BaseItem && it.type === ItemTypes.GameArea}
            polylineFilter={(it) => it instanceof POIItem && it.patrolCoordinates?.length}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(withModal, connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(MapReview);
