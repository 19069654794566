/**
 * @flow
 *
 * @format
 */
import type { Reference } from '@firebase/storage';

import Firebase from '../Firebase';

export type pushEditorAssetType = (sectionId: string, filename: string, file: any, firebase: Firebase) => Promise<void>;
export const pushEditorAssetAsync = async (sectionStorage: Reference, filename: string, file: File) => {
  const firebaseFile = sectionStorage.child(filename);
  const snapshot = await firebaseFile.put(file);
  const url = await snapshot.ref.getDownloadURL();
  return url;
};

export type pushReleaseAssetAsyncType = (
  scenarioId: string,
  version: string,
  subFolder?: string,
  filename: string,
  file: ?File,
  strContent: ?string,
  isPublic?: boolean,
  firebase: Firebase,
) => any;

export const pushReleaseAssetAsync = async (
  sectionStorage: Reference,
  version: string,
  subfolder?: string,
  filename: string,
  file?: ?File,
  strContent?: ?string,
  isPublic?: boolean,
) => {
  let firebaseFile;
  if (subfolder) {
    firebaseFile = sectionStorage.child(subfolder).child(filename);
  } else {
    firebaseFile = sectionStorage.child(filename);
  }
  const fileMeta = {
    customMetadata: {
      isPublic: isPublic || false,
    },
  };
  if (file) {
    await firebaseFile.put(file, fileMeta);
  } else if (strContent) {
    await firebaseFile.putString(strContent);
    await firebaseFile.updateMetadata(fileMeta);
  }
};

export type getEditorAssetUrlAsyncType = (scenarioId: string, filename: string) => Promise<string>;
export const getEditorAssetUrlAsync = async (sectionStorage: Reference, filename: string) => {
  const firebaseFile = sectionStorage.child(filename);
  const url = await firebaseFile.getDownloadURL();
  return url;
};

export type downloadAssetAsyncType = (sectionId: string, filename: string, firebase: Firebase) => Promise<?File>;

export const downloadFileAsync = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('GET', url);
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        resolve(request.response);
      } else {
        reject(request.statusText);
      }
    };
    request.onerror = () => {
      reject(request.statusText);
    };
    request.send();
  });

export const removeEditorFilesAsync = async (
  sectionId: string,
  fileNames: string[],
  part: string,
  firebase: Firebase,
): Promise<void> => {
  let storageSection;
  switch (part) {
    case 'scenario':
      storageSection = firebase.scenarioEditorStorage(sectionId);
      break;
    case 'ams':
      // TODO : Set storage part if we want to manage AMS images
      break;
    case 'cities':
      storageSection = firebase.cityEditorStorage(sectionId);
      break;
    case 'season':
      storageSection = firebase.seasonsEditorStorage(sectionId);
      break;

    default:
      throw new Error('');
  }

  fileNames.forEach((filename) => {
    if (filename.length) {
      const firebaseFile = storageSection.child(filename);
      firebaseFile.delete();
    }
  });
};

export type getNextVersionAsyncType = (elementId: string, firebase: Firebase) => Promise<string>;
export const getNextVersionAsync = async (sectionData: any) => {
  const snapshot = await sectionData.once('value');
  let max = 0;
  if (snapshot.exists()) {
    snapshot.forEach((child) => {
      const childId = child.key;
      if (childId.startsWith('v')) {
        const versionNumber = parseInt(childId.slice(1), 10);
        if (versionNumber > max) {
          max = versionNumber;
        }
      }
    });
  }
  return `v${max + 1}`;
};
