/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import update from 'immutability-helper';

import { InputBoolean, Loader, InputSelect, withConfirm } from 'src/pages/components';
import type { withConfirmProps } from 'src/pages/components';
import { LocalizedString, City, missionTypes } from 'src/data';
import type { ObjectMap, ScenarioVendingInfo, MissionType } from 'src/data';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { ScenarioServiceHelper } from 'src/store/scenario';
import VisibilitySensor from 'react-visibility-sensor';

import type { ProductItem } from 'src/data/types/ProductItemType';
import { TabContent, VisibilityAndVendingChanger } from '../components';

type Props = withConfirmProps & {
  firebase: Firebase,
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsyncType,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsyncType,
  locale: string,
  cities: City[],
  t: (key: string) => string,
};

type ScenarioInfo = {
  id: string,
  cityId: string,
  name: LocalizedString,
  subtitle: LocalizedString,
  vendingInfo: ScenarioVendingInfo,
  isOfficial: boolean,
  missionType: MissionType,
  lastVersion: string,
  currentVersion?: string,
  alert: any,
};

type State = {
  newScenarios?: ObjectMap<ScenarioInfo>,
  currentScenarios?: ObjectMap<ScenarioInfo>,
  matchingScenarios?: ObjectMap<ScenarioInfo>,
  newScenarioId?: string,
  scenarioId?: string,
  currentVersion?: string,
  lastVersion?: string,
  comingSoon: boolean,
  externalSeller: boolean,
  visible: boolean,
  wasVisible: boolean,
  isFree: boolean,
  price: ?number,
  iapSku: ?string,
  promoExpirationDate: ?number,
  hasMultipleEngines: boolean,
  removeOldReleasesAccess: boolean,
  expendable: boolean,
  isOfficial: boolean,
  missionType: MissionType,

  isNewDeploy: boolean,
  isLoading: boolean,
  isDeploying: boolean,
  isValid: boolean,

  changes: any[],
  editors: string[],
  itemIds: string[],
  sections: string[],
  preconfiguredProducts: ProductItem[],
  preconfiguredProductId?: string,

  // filters
  cityId?: string,
  searchString?: string,
};

class ReleasesTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    newScenarios: {},
    currentScenarios: {},
    matchingScenarios: {},
    newScenarioId: undefined,
    scenarioId: undefined,
    lastVersion: undefined,
    currentVersion: undefined,
    comingSoon: false,
    externalSeller: false,
    visible: false,
    wasVisible: false,
    isFree: false,
    price: null,
    iapSku: null,
    promoExpirationDate: null,
    hasMultipleEngines: false,
    removeOldReleasesAccess: false,
    expendable: false,
    isOfficial: false,
    missionType: missionTypes.isPrivate,

    isNewDeploy: false,
    isLoading: false,
    isDeploying: false,
    isValid: false,

    changes: [],
    editors: [],
    itemIds: [],
    sections: [],
    preconfiguredProducts: [],
    preconfiguredProductId: null,

    cityId: undefined,
    searchString: '',
  };

  onVisibilityChanged = (visible: boolean) => {
    if (visible) {
      this.reloadScenariosAsync().then(() => {
        this.reloadSkusAsync();
      });
    }
  };

  handleCityChange = (event) => {
    const { value } = event.target;
    // $FlowFixMe Boolean is only used for bool fields
    this.setState({ cityId: value }, () => {
      this.updateMatchingScenarios();
    });
  };

  handleChange = (event) => {
    const { value, id: fieldName } = event.target;
    if (
      event.target.id === 'isFree' &&
      !value &&
      this.state.scenarioId &&
      (!this.state.iapSku || !this.state.iapSku.length)
    ) {
      this.setState({ iapSku: `com.atlantide.vaiana.${this.state.scenarioId}` });
    }
    if (fieldName === 'preconfiguredProductId') {
      const product = value && this.state.preconfiguredProducts.find((it) => it.id === value);
      if (product) {
        const { id, price } = product;
        this.setState({ iapSku: decodeURI(id), price });
      } else {
        this.setState({ iapSku: `com.atlantide.vaiana.${this.state.scenarioId}`, price: 0 });
      }
    }
    this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
  };

  selectScenario = (data: ScenarioInfo) => {
    const { vendingInfo, isOfficial, missionType } = data;
    let nonNullEngineCount;
    try {
      nonNullEngineCount = data.versionPerEngine ? data.versionPerEngine.filter((it) => it !== null).length : 0;
    } catch (error) {
      nonNullEngineCount = Object.keys(data.versionPerEngine).length;
    }
    const isFree = !vendingInfo || vendingInfo.isFree || (!vendingInfo.price && !vendingInfo.iapSku);
    this.setState(
      {
        newScenarioId: undefined,
        scenarioId: data ? data.id : undefined,
        lastVersion: data.lastVersion,
        currentVersion: data.currentVersion,
        comingSoon: (vendingInfo && vendingInfo.comingSoon) || false,
        externalSeller: (vendingInfo && vendingInfo.externalSeller) || false,
        visible: (vendingInfo && vendingInfo.visible) || false,
        isFree,
        price: vendingInfo && vendingInfo.price,
        iapSku: vendingInfo && vendingInfo.iapSku,
        promoExpirationDate: vendingInfo && vendingInfo.promoExpirationDate,
        isNewDeploy: false,
        wasVisible: (vendingInfo && vendingInfo.visible) || false,
        hasMultipleEngines: nonNullEngineCount > 1,
        removeOldReleasesAccess: false,
        expendable: (vendingInfo && vendingInfo.expendable) || false,
        preconfiguredProductId:
          !isFree && vendingInfo && vendingInfo.expendable
            ? this.state.preconfiguredProducts.find((it) => decodeURI(it.id) === vendingInfo.iapSku).id
            : '',
        isOfficial,
        missionType,
      },
      () => {
        this.updateValidity(this.state);
        this.refreshChanges();
      },
    );
  };

  selectScenarioId = () => {
    const scenarioId = this.state.newScenarioId;
    this.setState({
      scenarioId,
      comingSoon: true,
      externalSeller: false,
      visible: false,
      isFree: true,
      price: 0,
      iapSku: undefined,
      promoExpirationDate: undefined,
      isNewDeploy: true,
      wasVisible: false,
      hasMultipleEngines: false,
      removeOldReleasesAccess: false,
      isOfficial: false,
      missionType: missionTypes.isPrivate,
    });
  };

  refreshChanges = async () => {
    const { firebase } = this.props;
    const { scenarioId, lastVersion, currentVersion } = this.state;
    const versions = [];
    if (lastVersion) {
      versions.push(lastVersion);
    }
    if (currentVersion) {
      versions.push(currentVersion);
    }
    if (scenarioId && lastVersion) {
      try {
        const { changes, editors, itemIds, sections } = await ScenarioServiceHelper.listChangesAsync(
          scenarioId,
          versions,
          firebase,
        );
        this.setState({
          changes,
          editors,
          itemIds,
          sections,
        });
      } catch (error) {
        console.error('Could not load changelist', error);
      }
    }
  };

  addScenarioAsync = async () => {
    await this.reloadScenariosAsync();
    this.setState(
      {
        scenarioId: undefined,
        newScenarioId: undefined,
      },
      () => {
        this.updateValidity(this.state);
      },
    );
  };

  reloadScenariosAsync = async () => {
    try {
      this.setState({ isLoading: true });
      const { newScenarios, currentScenarios } = await this.props.firebase.getScenariosToDeployAsync();
      this.setState({ newScenarios, currentScenarios, isLoading: false }, () => {
        this.updateMatchingScenarios();
      });
    } catch (error) {
      console.warn('Cannot load scenarios', error);
      this.setState({ isLoading: false });
    }
  };

  updateMatchingScenarios = () => {
    const { cityId } = this.state;
    const matchingScenarios = {};
    if (this.state.currentScenarios) {
      Object.keys(this.state.currentScenarios).forEach((it: string) => {
        if (cityId) {
          // $FlowFixMe indexer
          if (this.state.currentScenarios[it].cityId === cityId) {
            matchingScenarios[it] = this.state.currentScenarios[it];
          }
        } else {
          // $FlowFixMe indexer
          matchingScenarios[it] = this.state.currentScenarios[it];
        }
      });
    }
    this.setState({ matchingScenarios });
  };

  reloadSkusAsync = async () => {
    try {
      this.setState({ isLoading: true });
      const preconfiguredProducts = await this.props.firebase.getPreconfiguredProducts();
      this.setState({ preconfiguredProducts, isLoading: false });
    } catch (error) {
      console.warn('Cannot load availableSkus', error);
      this.setState({ isLoading: false });
    }
  };

  dryRunDeployLatestReleaseAsync = async () => {
    await this._deployToInternalAsync(true);
  };

  deployLatestReleaseAsync = async () => {
    await this._deployToInternalAsync(false);
  };

  _checkMultipleEngines = (yesCallback: () => void | Promise<void>, noCallback: () => void) => {
    const { hasMultipleEngines, wasVisible, visible } = this.state;
    const { t } = this.props;
    if (visible && !wasVisible && hasMultipleEngines) {
      this.props.alert(t('screens.admin.releaseInfo.confirmSetVisibleMultipleVersions'), [
        { text: t('general.confirm'), onPress: yesCallback },
        { text: t('general.cancel'), onPress: noCallback },
      ]);
    } else {
      yesCallback();
    }
  };

  _deployToInternalAsync = async (dryRun: boolean) => {
    const {
      scenarioId,
      removeOldReleasesAccess,
      comingSoon,
      visible,
      isFree,
      price,
      iapSku,
      promoExpirationDate,
      externalSeller,
      expendable,
      isOfficial,
      missionType,
    } = this.state;
    const { deployToInternalAsync, firebase } = this.props;
    if (scenarioId && deployToInternalAsync) {
      const generate = async () => {
        try {
          this.setState({ isDeploying: true });

          const newVendingInfo: ScenarioVendingInfo = ScenarioServiceHelper.prepareVendingInfoToDeploy(
            comingSoon,
            visible,
            isFree,
            price,
            iapSku,
            promoExpirationDate,
            externalSeller,
            expendable,
          );
          await deployToInternalAsync(
            scenarioId,
            newVendingInfo,
            isOfficial,
            missionType,
            removeOldReleasesAccess,
            false,
            firebase,
            dryRun,
          );
          this.setState({ isDeploying: false, newScenarioId: undefined });
          if (!dryRun) {
            this.setState({ isNewDeploy: false });
          }
        } catch (error) {
          this.setState({ isDeploying: false });
        }
        await this.reloadScenariosAsync();
      };
      this._checkMultipleEngines(generate, () => {});
    }
  };

  _checkVersion = async () => {
    const { checkScenarioVersionAsync } = this.props;
    const { scenarioId } = this.state;
    try {
      this.setState({ isLoading: true });
      await checkScenarioVersionAsync(scenarioId);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  saveVendingInfo = () => {
    this._checkMultipleEngines(this.saveVendingInfoAsync, () => {});
  };

  saveVendingInfoAsync = async () => {
    const {
      scenarioId,
      comingSoon,
      visible,
      isFree,
      price,
      iapSku,
      promoExpirationDate,
      externalSeller,
      expendable,
      isOfficial,
      missionType,
    } = this.state;
    this.setState({ isLoading: true });
    if (scenarioId) {
      try {
        const newVendingInfo: ScenarioVendingInfo = ScenarioServiceHelper.prepareVendingInfoToDeploy(
          comingSoon,
          visible,
          isFree,
          price,
          iapSku,
          promoExpirationDate,
          externalSeller,
          expendable,
        );
        await this.props.firebase.updateVendingInfoAsync(scenarioId, newVendingInfo, isOfficial, missionType);
        this.setState(
          (prevState) =>
            update(prevState, {
              currentScenarios: {
                [scenarioId]: {
                  vendingInfo: { $set: newVendingInfo },
                  isOfficial: { $set: isOfficial },
                  missionType: { $set: missionType },
                },
              },
            }),
          () => {
            this.updateMatchingScenarios();
          },
        );
      } catch (error) {
        console.warn(`Cannot update scenario '${scenarioId}'`, error);
      }
    }
    this.setState({ isLoading: false });
  };

  updateValidity = (newVal: State) => {
    const isValid: boolean = newVal.isFree || (newVal.price !== 0 && !!newVal.iapSku);
    this.setState({ isValid });
  };

  renderListButton = (element: ScenarioInfo) => {
    const { locale } = this.props;
    const cityId = element.cityId || '??';
    const scenarioId = this.state.scenarioId || '??';
    let isActive = false;
    let buttonClass = 'list-group-item list-group-item mb-3 list-group-item-action align-items-start';
    if (scenarioId !== '??' && element.id === scenarioId) {
      buttonClass += ' active';
      isActive = true;
    }

    const { id, name, subtitle, currentVersion, lastVersion } = element;

    let versionString;
    if (currentVersion && currentVersion !== lastVersion) {
      versionString = `${currentVersion} => ${lastVersion}`;
      if (!isActive) {
        buttonClass += ' list-group-item-warning';
      }
    } else {
      versionString = `Latest (${lastVersion})`;
    }
    return (
      <div className="" key={id}>
        <button id={id} className={buttonClass} onClick={() => this.selectScenario(element)}>
          <div className="d-flex justify-content-between">
            <h5 className="mb-1">{name.valueForLocale(locale)}</h5>
            <small className="text-muted">{`${cityId}/${id}`}</small>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-1">{subtitle.valueForLocale(locale)}</p>
            <small className="text-muted">{versionString}</small>
          </div>
        </button>
      </div>
    );
  };

  render() {
    const {
      newScenarioId,
      newScenarios,
      matchingScenarios,
      scenarioId,
      comingSoon,
      externalSeller,
      visible,
      isFree,
      price,
      iapSku,
      promoExpirationDate,
      removeOldReleasesAccess,
      expendable,
      preconfiguredProductId,
      preconfiguredProducts,
      missionType,

      isLoading,
      isDeploying,
      isValid,
    } = this.state;
    const { locale, t } = this.props;
    return (
      <TabContent name="releaseInfo">
        <React.Fragment>
          <div className="card bg-light  screenBlock">
            <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
              <div className="card-header">
                <h3>
                  {t('screens.admin.releaseInfo.sectionTitle')} /{' '}
                  <small>{`${scenarioId || ''}${
                    newScenarioId ? t('screens.admin.releaseInfo.firstDeploy') : ''
                  }`}</small>
                </h3>
              </div>
            </VisibilitySensor>
            <div className="card-body d-flex p-2 pl-4" style={{ height: '60vh' }}>
              <div className="row">
                <div className="list-group col-4 pb-2" style={{ height: '100%', overflowY: 'scroll' }}>
                  <div className="addButton">
                    <button
                      id="addButton"
                      className="list-group-item list-group-item mb-3 list-group-item-action align-items-start"
                      onClick={this.addScenarioAsync}
                    >
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-1">{t('screens.admin.releaseInfo.addScenario')}</h5>
                      </div>
                    </button>
                  </div>
                  <InputSelect
                    fieldName={'cityId'}
                    value={this.state.cityId}
                    values={this.props.cities}
                    itemToId={(it) => it.id}
                    itemToTitle={(it) => it.name.valueForLocale(locale)}
                    label={'Ville'}
                    handleChange={this.handleCityChange}
                  />
                  {matchingScenarios &&
                    /* $FlowFixMe: Object.values */
                    Object.values(matchingScenarios).map((element: ScenarioInfo) => this.renderListButton(element))}
                </div>
                {scenarioId && (
                  <div
                    className="list-group col-8 d-flex pb-4"
                    style={{ paddingLeft: 5, height: '100%', overflowY: 'scroll' }}
                  >
                    <VisibilityAndVendingChanger
                      scenarioId={scenarioId}
                      comingSoon={comingSoon}
                      externalSeller={externalSeller}
                      visible={visible}
                      isFree={isFree}
                      price={price}
                      iapSku={iapSku}
                      promoExpirationDate={promoExpirationDate}
                      expendable={expendable}
                      preconfiguredProductId={preconfiguredProductId}
                      preconfiguredProducts={preconfiguredProducts}
                      handleChange={this.handleChange}
                      missionType={missionType}
                      cardStyle
                    />
                    <div>
                      <h5>{t('screens.admin.releaseInfo.changeList')}</h5>
                      <h6>
                        {t('screens.admin.releaseInfo.editors')} <small>{this.state.editors.join(', ')}</small>
                      </h6>
                      <h6>
                        {t('screens.admin.releaseInfo.sections')} <small>{this.state.sections.join(', ')}</small>
                      </h6>
                      <h6>
                        {t('screens.admin.releaseInfo.items')} <small>{this.state.itemIds.join(', ')}</small>
                      </h6>
                    </div>
                    <div style={{ minHeight: 30 }}>
                      <InputBoolean
                        fieldName="removeOldReleasesAccess"
                        value={removeOldReleasesAccess}
                        label={t('screens.admin.releaseInfo.removeOldReleasesAccessLabel')}
                        help={t('screens.admin.releaseInfo.removeOldReleasesAccessHelp')}
                        handleChange={this.handleChange}
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-save"
                        onClick={this.saveVendingInfo}
                        disabled={!isValid || !!newScenarioId}
                      >
                        {t('general.save')}
                      </button>
                      <button
                        className="btn btn-outline-warning ml-2"
                        type="button"
                        id="button-deploy"
                        onClick={this.deployLatestReleaseAsync}
                      >
                        {t('screens.admin.releaseInfo.deploy')}
                      </button>
                      <button
                        className="btn btn-outline-warning ml-2 hidden"
                        type="button"
                        id="button-deploy"
                        onClick={this.dryRunDeployLatestReleaseAsync}
                      >
                        {t('screens.admin.releaseInfo.deployDry')}
                      </button>
                      <button
                        className="btn btn-outline-secondary ml-2"
                        type="button"
                        id="button-check"
                        onClick={this._checkVersion}
                      >
                        {t('screens.admin.releaseInfo.checkIntegrity')}
                      </button>
                    </div>
                  </div>
                )}
                {!scenarioId && (
                  <div className="list-group col-8" style={{ height: '100%', overflowY: 'scroll' }}>
                    <div className="card p-2 mb-2">
                      <InputSelect
                        fieldName="newScenarioId"
                        value={newScenarioId}
                        values={Object.values(newScenarios)}
                        itemToId={(it) => it.id}
                        itemToTitle={(it) => `${it.id} - ${it.name.valueForLocale(locale)}`}
                        label={t('screens.admin.releaseInfo.newScenarioLabel')}
                        handleChange={this.handleChange}
                        help={t('screens.admin.releaseInfo.newScenarioHelp')}
                      />
                    </div>
                    <button
                      className="btn btn-outline-secondary mb-3"
                      type="button"
                      id="button-addon2"
                      onClick={this.selectScenarioId}
                      disabled={!newScenarioId}
                    >
                      {t('screens.admin.releaseInfo.startReleaseProcess')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(isDeploying || isLoading) && <Loader />}
        </React.Fragment>
      </TabContent>
    );
  }
}

const sortCities = (a: City, b: City) => a.id.localeCompare(b.id, 'fr');

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
  cities: Object.values(state.configuration.availableCities).sort(sortCities),
});

const mapDispatchToProps = {
  checkScenarioVersionAsync: FirebaseHelper.checkScenarioVersionAsync,
  deployToInternalAsync: ScenarioServiceHelper.deployToInternalAsync,
};

export default compose(
  withConfirm,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(ReleasesTab);
