/** @format */

export const inputSeasonScenariosToSeasonScenarios = (input) => {
  const finalResult = [];
  if (input) {
    const parsedInput = JSON.parse(input);
    parsedInput.forEach((array) => {
      finalResult.push(array[0]);
    });
  }
  return finalResult;
};

export const seasonScenariosToInputSeasonScenarios = (scenarios) => {
  const finalResult = [];
  if (scenarios) {
    scenarios.forEach((scenario) => {
      finalResult.push([scenario]);
    });
  }
  return JSON.stringify(finalResult);
};
