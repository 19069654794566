/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { Switch, BrowserRouter, Route } from 'react-router-dom';

import Tour from 'reactour';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { UserServiceHelper } from 'src/store/user';
import Loader, { MaintenanceLoader } from 'src/pages/components/loader';
import { InformationalCountdown } from 'src/pages/components';
import * as ROUTES from 'src/constants/routes';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { withAuthentication } from 'src/services/Session';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@magnitude-labs/fortawesome-pro-solid-svg-icons';
import { fal } from '@magnitude-labs/fortawesome-pro-light-svg-icons';
import { far } from '@magnitude-labs/fortawesome-pro-regular-svg-icons';
import { fad } from '@magnitude-labs/fortawesome-pro-duotone-svg-icons';
import { hotjar } from 'react-hotjar';
import { ConfigurationServiceHelper } from 'src/store/configuration';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/assets/styles/app.scss';

import logo from 'src/assets/logo ATLANTIDE.png';
import type { AtlEditorState } from 'src/store';
import type { ScenarioReducerState } from 'src/store/scenario';
import { ScenarioServiceHelper } from 'src/store/scenario';
import withEditionSearch from 'src/pages/scenario/withEditionSearch';
import { MenuItem } from '@blueprintjs/core';
import { Omnibar } from '@blueprintjs/select';
import { AtlObject } from 'src/data';
import { Constants, Colors } from 'src/assets';
import { GlobalHotKeys } from 'react-hotkeys';
import SeasonsScreen from 'src/pages/seasons/SeasonsScreen';
import EditSeasonScreen from 'src/pages/seasons/EditSeasonScreen';
import NewSeasonScreen from 'src/pages/seasons/NewSeasonScreen';
import LoginScreen from '../LoginScreen';
import SettingsScreen from '../settings/SettingsScreen';
import MyReleasesScreen from '../releases/MyReleasesScreen';
import { NavBar } from '../components/NavBar';
import { ScenariosDashboardScreen, NewScenarioScreen } from '../scenario';
import { AdminScreen } from '../admin';
import NotificationsView from '../components/notifications/NotificationsView';
import ScenarioEditionRouter from '../scenario/ScenarioEditionRouter';
import ErrorBoundary from './ErrorBoundary';
import ConsentOverflow from './ConsentOverflow';
import ReviewScreen from '../review/ReviewScreen';

// FA INIT
library.add(fab, fas, fal, far, fad);
// check https://fontawesome.com/icons?d=gallery for icons !

type Props = {
  reduxState: ScenarioReducerState,
  setUser: UserServiceHelper.setUserType,
  updateOnFirebase: ScenarioServiceHelper.saveScenarioInFirebaseType,
  getAvailableTours: ConfigurationServiceHelper.getAvailableToursType,
  setTourViewed: ConfigurationServiceHelper.setTourViewedAsyncType,
  firebase: Firebase,
  isMaintaining: boolean,
  email?: string,
  maintenanceData: { maintainers?: string[], startTime?: number },
  updateRequired: boolean,
  nextDeploymentTime: number,
  requiredEditorVersion: string,
  t: (key: string[]) => string,
  hasHandlers?: boolean,
  user: any,
  handlers: Function[],
  items: AtlObject[],
  loggedIn: boolean,
  editorTosAgreed: boolean,
  editorConfidentialityAgreed: boolean,
};

type state = {
  isLoading: boolean,
  isOpen: boolean,
  tourSteps: { message: string, anchor: string }[],
  isTourOpen: boolean,
  tourId?: string,
};

const EditorActionOmnibar = Omnibar.ofType<AtlObject>();

class App extends React.Component<Props, state> {
  state = {
    isLoading: false,
    isOpen: false,
    tourSteps: [],
    isTourOpen: false,
    tourId: undefined,
  };

  listener: (authUser: any) => void;

  componentDidMount() {
    document.title = Constants.editorName;

    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      const { setUser } = this.props;
      if (setUser) {
        setUser(authUser);
      }
    });

    this.initHotjar();
  }

  componentWillUnmount() {
    this.listener();
  }

  componentDidUpdate = (oldProps) => {
    const oldUser = JSON.stringify(oldProps.user || {});
    const newUser = JSON.stringify(this.props.user || {});
    if (this.props.user && oldUser !== newUser) {
      this.onRouteChange(window.location.pathname);
    }
  };

  handleToggle = (event) => {
    event.preventDefault();
    if (this.props.hasHandlers) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  initHotjar = () => {
    const { hotjarId, hotjarVersion } = Constants;

    if (hotjarId && hotjarVersion) {
      hotjar.initialize(hotjarId, hotjarVersion);
    }
  };

  renderMaintenance = () => {};

  handleItemSelect = (item: AtlObject) => {
    this.setState({ isOpen: false });
    const { handlers } = this.props;
    handlers.forEach((it) => {
      it(item);
    });
  };

  escapeRegExpChars = (text: string) => {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
  };

  highlightText = (text: string, query: string) => {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(this.escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens: React.ReactNode[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const { length } = match[0];
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      // eslint-disable-next-line prefer-destructuring
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  };

  renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={item.getDisplayTitle()}
        key={item.id}
        onClick={handleClick}
        text={this.highlightText(item.getDisplayTitle(), query)}
      />
    );
  };

  openHandlers = {
    OPEN: this.handleToggle,
  };

  closeHandlers = {
    CLOSE: this.handleToggle,
  };

  handleClose = () => this.setState({ isOpen: false });

  onRouteChange = async (newPath: string) => {
    try {
      const tours = await this.props.getAvailableTours(newPath, this.props.user, this.props.firebase);
      this.setState({
        tourSteps: (tours && tours[0]?.steps) || [],
        isTourOpen: tours && tours.length,
        tourId: tours && tours[0]?.id,
      });
    } catch (error) {
      console.error('Ops', error);
    }
  };

  closeTour = async () => {
    // TODO : Set viewed
    await this.props.setTourViewed(this.state.tourId, this.props.user, this.props.firebase);
    this.setState({ isTourOpen: false });
  };

  renderPages = () => {
    const {
      isMaintaining,
      updateRequired,
      nextDeploymentTime,
      loggedIn,
      editorTosAgreed,
      editorConfidentialityAgreed,
    } = this.props;
    const { isLoading, isTourOpen, tourSteps } = this.state;

    const showConsent = loggedIn && !(editorTosAgreed && editorConfidentialityAgreed);
    return (
      <React.Fragment>
        <div className="" style={{ width: '100%' }}>
          <Switch className="pageContainer" style={{ width: '100%' }}>
            <Route exact path={ROUTES.SIGN_IN} component={LoginScreen} />
            <div className="page active" logo={logo} style={{ width: '100%' }}>
              <ToastContainer
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              <Tour
                accentColor={Colors.aqua}
                steps={tourSteps}
                isOpen={isTourOpen}
                onRequestClose={this.closeTour}
                badgeContent={(curr, tot) => (
                  <p style={{ color: 'black' }}>
                    {curr} / {tot}
                  </p>
                )}
              />
              <NavBar logo={logo} updateOnFirebase={this.updateOnFirebase} onRouteChange={this.onRouteChange} />
              {showConsent ? (
                <ConsentOverflow />
              ) : (
                <Switch className="pageContainer">
                  {/* Scenarios routes */}
                  <Route exact path={ROUTES.SCENARIO_DASHBOARD} component={ScenariosDashboardScreen} />
                  {<Route path={ROUTES.SCENARIO_NEW} component={NewScenarioScreen} />}

                  {<Route path={ROUTES.SCENARIO_EDITION_ALL} component={ScenarioEditionRouter} />}

                  {/* Settings routes */}
                  <Route exact path={ROUTES.SETTINGS} component={SettingsScreen} />
                  {/* Releases routes */}
                  <Route exact path={ROUTES.RELEASES} component={MyReleasesScreen} />
                  {/* Admin routes */}
                  <Route exact path={ROUTES.ADMIN} component={AdminScreen} />
                  {/* Review routes */}
                  <Route exact path={ROUTES.REVIEW} component={ReviewScreen} />
                  {/* Seasons routes */}
                  <Route exact path={ROUTES.SEASONS} component={SeasonsScreen} />
                  {<Route path={ROUTES.SEASONS_NEW} component={NewSeasonScreen} />}
                  {<Route path={ROUTES.SEASONS_EDIT} component={EditSeasonScreen} />}

                  {/* Default route */}
                  {<Route exact path={''} component={ScenariosDashboardScreen} />}
                </Switch>
              )}
              <NotificationsView />
            </div>
          </Switch>
          {isLoading && <Loader />}
          {isMaintaining && !this.props.maintenanceData.maintainers.includes(this.props.email) && (
            <MaintenanceLoader
              maintainers={this.props.maintenanceData.maintainers}
              startTime={this.props.maintenanceData.startTime}
            />
          )}
          {updateRequired && <MaintenanceLoader requiredVersion={this.props.requiredEditorVersion} />}
          {!!nextDeploymentTime && (
            <InformationalCountdown
              endTime={nextDeploymentTime}
              messageKey={'general.updateTimerMessage'}
              titleKey={'general.updateTimerTitle'}
              tipKey={'general.updateTip'}
            />
          )}
        </div>
        <EditorActionOmnibar
          isOpen={this.state.isOpen}
          itemPredicate={AtlObject.filterObjects}
          itemRenderer={this.renderItem}
          items={this.state.isOpen ? this.props.items : []}
          itemsEqual={'id'}
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={this.handleItemSelect}
          onClose={this.handleClose}
          resetOnSelect={true}
        />
      </React.Fragment>
    );
  };

  updateOnFirebase = async () => {
    const { reduxState, updateOnFirebase, firebase } = this.props;
    this.setState({ isLoading: true });
    if (updateOnFirebase && reduxState) {
      await updateOnFirebase(reduxState.header.id, reduxState, firebase);
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <ErrorBoundary>
        <React.Suspense fallback={<div className="loader-container" />}>
          <GlobalHotKeys
            handlers={this.openHandlers}
            keyMap={{
              OPEN: 'shift+o',
            }}
          />
          <GlobalHotKeys
            handlers={this.closeHandlers}
            keyMap={{
              CLOSE: 'esc',
            }}
          />
          <BrowserRouter>{this.renderPages()}</BrowserRouter>
        </React.Suspense>{' '}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  reduxState: state.scenario,
  items: (state.scenario && state.scenario.items && Object.values(state.scenario.items)) || [],
  user: state.user.user,
  loggedIn: !!state.user.user,
  editorTosAgreed: state.user.user?.editorTosAgreed,
  editorConfidentialityAgreed: state.user.user?.editorConfidentialityAgreed,
});

const mapDispatchToProps = {
  updateOnFirebase: ScenarioServiceHelper.exportScenarioInFirebase,
  getAvailableTours: ConfigurationServiceHelper.getAvailableTours,
  setTourViewed: ConfigurationServiceHelper.setTourViewedAsync,
  setUser: UserServiceHelper.setUser,
};

export default compose(
  withEditionSearch,
  withAuthentication,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  }),
)(App);
